import { customFetch } from "../utils/helper";

export let getQrMenuRoom = async (roomId: string) => {
  return await customFetch(`qrMenu/getRoom/${roomId}?is_pinned=1`);
};

export let getQrMenuRoomProducts = async (roomId: string, categoryId?: string, search?: string) => {
  var params: Record<string, string> = {};

  if (categoryId) {
    params["product_category_id"] = categoryId
  }
  if (search) {
    params["search"] = search
  }

  return await customFetch(
    `qrMenu/${roomId}/products?${new URLSearchParams(params)}`
  );
};
