import {
  Machine,
  Merchant,
  MerchantRoom,
  MerchantStation,
  PaymentProvider,
  PaymentProviderReq,
} from "../../model/merchant";
import { LoadingContext } from "../../objects/loading_context";
import {
  addMachine,
  addMerchantAccount,
  addMerchantAllProduct,
  addMerchantDiscount,
  addMerchantPriceCategory,
  addMerchantProduct,
  addMerchantProductCategory,
  addMerchantStore,
  addMerchantTax,
  addMerchantUser,
  deleteMachine,
  deleteMerchantAccount,
  deleteMerchantDiscount,
  deleteMerchantPriceCategory,
  deleteMerchantProduct,
  deleteMerchantProductCategory,
  deleteMerchantStore,
  deleteMerchantTax,
  deleteMerchantUser,
  getMerchantAccounts,
  getMerchantDetail,
  getMerchantDiscounts,
  getMerchantMachines,
  getMerchantPriceCategories,
  getMerchantProductCategories,
  getMerchantProducts,
  getMerchantStores,
  getMerchantTaxes,
  getMerchantUsers,
  pinnedMerchantProduct,
  updateMachine,
  updateMerchant,
  updateMerchantProduct,
} from "../../repositories/merchant";
import { useContext, useEffect, useRef, useState, type FC } from "react";
import {
  Button,
  Drawer,
  Input,
  Modal,
  Panel,
  SelectPicker,
  Stack,
  Table,
  Tag,
  TagPicker,
  Toggle,
} from "rsuite";
import Swal from "sweetalert2";
import InlineForm from "../inline_form";
import {
  CameraIcon,
  CheckCircleIcon,
  CheckIcon,
  FolderIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  confirmDelete,
  randomStr,
  setActiveMerchant,
  setNullString,
} from "../../utils/helper";
import { Switch } from "@headlessui/react";
import { Tax } from "../../model/tax";
import { getTaxes } from "../../repositories/tax";
import { Store } from "../../model/store";
import { getStores } from "../../repositories/store";
import {
  getPriceCategories,
  getProductCategories,
  getProducts,
} from "../../repositories/product";
import {
  PriceCategory,
  Product,
  ProductCategory,
  Unit,
} from "../../model/product";
import { getAccounts } from "../../repositories/account";
import { Account } from "../../model/account";
import CustomTable, { TableRecord } from "../custom_table";
import { ACCOUNT_LABELS } from "../../objects/constants";
import ModalAccount from "../modals/modal_account";
import { money } from "../../utils/number";
import ModalCustom from "../modals/modal_custom";
import CurrencyInput from "react-currency-input-field";
import Select, { SingleValue } from "react-select";
import { SelectOption, colourStyles } from "../../utils/style";
import ModalSearch from "../modals/modal_search";
import Avatar from "../avatar";
import { CompanyMember } from "../../model/company";
import { getCompanyMembers } from "../../repositories/company";
import { Discount } from "../../model/discount";
import { getDiscounts } from "../../repositories/discount";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../utils/helper-ui";
import { FaGear } from "react-icons/fa6";
import { PiGear } from "react-icons/pi";
import { fileUpload } from "../../utils/file";
import {
  addPosRoom,
  addPosStation,
  deleteRoom,
  deleteStation,
  getPosRoom,
  getPosStation,
  updatePosRoomDetail,
} from "../../repositories/pos";
import { error } from "console";
import { VscEditorLayout } from "react-icons/vsc";
import { MdOutlineLocalDining } from "react-icons/md";
import QRCode from "react-qr-code";
import { Shape } from "../../model/shape";
interface CashierProps {}

const CashierSetting: FC<CashierProps> = ({}) => {
  const [mounted, setMounted] = useState(false);
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [cover, setCover] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [address, setAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactPersonPosition, setContactPersonPosition] = useState("");
  const [priceTaxInclude, setPriceTaxInclude] = useState(false);
  const [isTableMapping, setIsTableMapping] = useState(false);
  const [isMerchantStation, setIsMerchantStation] = useState(false);
  const [isQrMenuEnabled, setIsQrMenuEnabled] = useState(false);
  const [qrMenuMainColor, setQrMenuMainColor] = useState("");
  const [qrMenuSecondaryColor, setQrMenuSecondaryColor] = useState("");
  const [message, setMessage] = useState("");
  const [messageToQr, setMessageToQr] = useState("");
  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [taxId, setTaxId] = useState("");
  const [stores, setStores] = useState<Store[]>([]);
  const [defaultStoreId, setDefaultStoreId] = useState("");
  const [defaultPriceCategoryId, setDefaultPriceCategoryId] = useState("");
  const [orderFlow, setOrderFlow] = useState("");
  const [defaultSaleAccountId, setDefaultSaleAccountId] = useState("");
  const [incomeAccounts, setIncomeAccounts] = useState<Account[]>([]);
  const [allPriceCategories, setAllPriceCategories] = useState<PriceCategory[]>(
    []
  );
  const [checkStock, setCheckStock] = useState(false);
  const [assetAccounts, setAssetAccounts] = useState<Account[]>([]);
  const [merchantAccounts, setMerchantAccounts] = useState<Account[]>([]);
  const [providers, setProviders] = useState<PaymentProvider[]>([]);
  const [modalAccountOpen, setModalAccountOpen] = useState(false);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [modalMachineOpen, setModalMachineOpen] = useState(false);
  const [inputMachineName, setInputMachineName] = useState("");
  const [inputDeposit, setInputDeposit] = useState(0);
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    []
  );
  const [allProductCategories, setAllProductCategories] = useState<
    ProductCategory[]
  >([]);
  const [modalProductCategoryOpen, setModalProductCategoryOpen] =
    useState(false);
  const [priceCategories, setPriceCategories] = useState<PriceCategory[]>([]);
  const [modalPriceCategoryOpen, setModalPriceCategoryOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [modalProductOpen, setModalProductOpen] = useState(false);
  const [modalStationOpen, setModalStationOpen] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [productSelect, setProductSelect] = useState<SelectOption | null>();
  const [unitSelect, setUnitSelect] = useState<SelectOption | null>();
  const [units, setUnits] = useState<Unit[]>([]);
  const [modalServiceOpen, setModalServiceOpen] = useState(false);
  const [isProductPinned, setIsProductPinned] = useState(false);
  const nav = useNavigate();
  const [modalStoreOpen, setModalStoreOpen] = useState(false);
  const [merchantStores, setMerchantStores] = useState<Store[]>([]);
  const [searchStore, setSearchStore] = useState("");
  const [allStores, setAllStores] = useState<Store[]>([]);
  const [merchantUsers, setMerchantUsers] = useState<CompanyMember[]>([]);
  const [modalMerchantUserOpen, setModalMerchantUserOpen] = useState(false);
  const [allMembers, setAllMembers] = useState<CompanyMember[]>([]);
  const [merchantTaxes, setMerchantTaxes] = useState<Tax[]>([]);
  const [modalTaxOpen, setModalTaxOpen] = useState(false);
  const [modalDiscountOpen, setModalDiscountOpen] = useState(false);
  const [merchantDiscounts, setMerchantDiscounts] = useState<Discount[]>([]);
  const [allDiscounts, setAllDiscounts] = useState<Discount[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const fileRef3 = useRef<HTMLInputElement>(null);
  const fileRef4 = useRef<HTMLInputElement>(null);
  const [stationName, setStationName] = useState("");
  const [stationDesc, setStationDesc] = useState("");
  const [stationPicturePath, setStationPicturePath] = useState("");
  const [stationPictureURL, setStationPictureURL] = useState("");
  const [qrisPicturePath, setQrisPicturePath] = useState("");
  const [qrisPictureURL, setQrisPictureURL] = useState("");
  const [stations, setStations] = useState<MerchantStation[]>([]);
  const [modalRoomOpen, setModalRoomOpen] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [roomDesc, setRoomDesc] = useState("");
  const [roomPictureURL, setRoomPictureURL] = useState("");
  const [roomPicturePath, setRoomPicturePath] = useState("");
  const [roomLength, setRoomLength] = useState(0);
  const [roomWidth, setRoomWidth] = useState(0);
  const [roomFloor, setRoomFloor] = useState(0);
  const [rooms, setRooms] = useState<MerchantRoom[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<MerchantRoom>();
  const [modeFormTable, setModeFormTable] = useState(false);
  const [selectedShape, setSelectedShape] = useState<Shape | null>(null);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    getDetail();
    getTaxes({ page: 1, limit: 1000, search: "", type: "Liability" })
      .then((v) => v.json())
      .then((v) => setTaxes(v.data));
    getDiscounts({ page: 1, limit: 1000, search: "" })
      .then((v) => v.json())
      .then((v) => setAllDiscounts(v.data));
    getStores({ page: 1, limit: 1000, search: "" })
      .then((v) => v.json())
      .then((v) => {
        setStores(v.data);
        setAllStores(v.data);
      });
    getPriceCategories({ page: 1, limit: 1000, search: "" })
      .then((v) => v.json())
      .then((v) => setAllPriceCategories(v.data));
    getAccounts({
      page: 1,
      limit: 10,
      type: "Asset",
      cashflowGroup: "current_asset",
    })
      .then((v) => v.json())
      .then((v) => {
        setAssetAccounts(v.data);
      });
    getAccounts({
      page: 1,
      limit: 10,
      type: "Income",
    })
      .then((v) => v.json())
      .then((v) => {
        setIncomeAccounts(v.data);
      });
    getMerchantAccounts()
      .then((v) => v.json())
      .then((v) => {
        setMerchantAccounts(v.data);
      });
    getMerchantMachines()
      .then((v) => v.json())
      .then((v) => {
        setMachines(v.data);
      });
    getMerchantProductCategories()
      .then((v) => v.json())
      .then((v) => {
        setProductCategories(v.data);
      });
    getProductCategories({ page: 1, limit: 1000 })
      .then((v) => v.json())
      .then((v) => {
        setAllProductCategories(v.data);
      });
    getMerchantPriceCategories()
      .then((v) => v.json())
      .then((v) => {
        setPriceCategories(v.data);
      });
    getMerchantProducts()
      .then((v) => v.json())
      .then((v) => {
        setProducts(v.data);
      });

    getProducts({ page: 1, limit: 10, search: searchProduct, isSell: true })
      .then((v) => v.json())
      .then((v) => {
        setAllProducts(v.data);
      });

    getMerchantStores()
      .then((v) => v.json())
      .then((v) => {
        setMerchantStores(v.data);
      });
    getMerchantUsers()
      .then((v) => v.json())
      .then((v) => {
        setMerchantUsers(v.data);
      });

    getCompanyMembers()
      .then((v) => v.json())
      .then((v) => {
        setAllMembers(v.data);
      });

    getMerchantTaxes()
      .then((v) => v.json())
      .then((v) => {
        setMerchantTaxes(v.data);
      });

    getMerchantDiscounts()
      .then((v) => v.json())
      .then((v) => {
        setMerchantDiscounts(v.data);
      });
    getPosStation()
      .then((v) => v.json())
      .then((v) => {
        setStations(v.data);
      });
    getPosRoom()
      .then((v) => v.json())
      .then((v) => {
        setRooms(v.data);
      });
  }, [mounted]);

  useEffect(() => {
    if (!merchant) return;
    setName(merchant!.name);
    setLogo(merchant!.logo);
    setCover(merchant!.cover);
    setEmail(merchant!.email);
    setPhone(merchant!.phone);
    setFax(merchant!.fax);
    setAddress(merchant!.address);
    setContactPerson(merchant!.contact_person);
    setContactPersonPosition(merchant!.contact_person_position);
    setTaxId(merchant!.tax_id);
    setPriceTaxInclude(merchant!.price_tax_include);
    setIsTableMapping(merchant!.is_table_mapping);
    setIsMerchantStation(merchant!.is_merchant_station);
    setMessage(merchant!.message);
    setMessageToQr(merchant!.message_to_qr);
    setDefaultStoreId(merchant!.default_store_id);
    setDefaultSaleAccountId(merchant!.default_sale_account_id);
    setDefaultPriceCategoryId(merchant!.default_price_category_id);
    setCheckStock(merchant!.check_stock);
    setProviders(merchant!.payment_providers);
    setOrderFlow(merchant!.order_flow);
    setActiveMerchant(merchant);
    setQrisPictureURL(merchant?.qris_static_picture ?? "");
    setIsQrMenuEnabled(merchant?.is_qr_menu_enabled);
    setQrMenuMainColor(merchant?.qr_menu_main_color ?? "");
    setQrMenuSecondaryColor(merchant?.qr_menu_secondary_color ?? "");
  }, [merchant]);

  const getDetail = () => {
    setIsLoading(true);
    getMerchantDetail()
      .then((v) => v.json())
      .then((v) => setMerchant(v.data))
      .catch((error) => Swal.fire("Perhatian", `${error}`, "error"))
      .finally(() => setIsLoading(false));
  };

  const merchantUpdate = async (
    qrisPath?: string | null,
    logoPath?: string | null
  ) => {
    try {
      setIsLoading(true);
      var resp = await updateMerchant({
        name: name,
        default_store_id: setNullString(defaultStoreId),
        tax_id: setNullString(taxId),
        tax_price_include: priceTaxInclude,
        message: message,
        message_to_qr: messageToQr,
        check_stock: checkStock,
        price_tax_include: priceTaxInclude,
        email: setNullString(email),
        phone: setNullString(phone),
        fax: setNullString(fax),
        address: setNullString(address),
        logo: logoPath ?? "",
        contact_person: setNullString(contactPerson),
        contact_person_position: setNullString(contactPersonPosition),
        default_price_category_id: setNullString(defaultPriceCategoryId),
        order_flow: orderFlow,
        is_merchant_station: isMerchantStation,
        is_table_mapping: isTableMapping,
        is_qr_menu_enabled: isQrMenuEnabled,
        qr_menu_main_color: qrMenuMainColor,
        qr_menu_secondary_color: qrMenuSecondaryColor,
        payment_providers: merchant!.payment_providers.map((e) => {
          return {
            uuid: setNullString(e.uuid),
            provider: e.provider,
            account_destination_id: setNullString(e.account_destination_id),
          };
        }),
        qris_static_picture: qrisPath,
      });
      var respJson = await resp.json();
      getDetail();
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const info = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-2">
        <Panel header="Info Merchant" bordered className="mb-8">
          <InlineForm title={"Logo"}>
            <div onClick={() => fileRef4.current?.click()}>
              {logo ? (
                <img src={logo} className="  object-cover w-60" />
              ) : (
                <CameraIcon className="w-10" />
              )}
            </div>
          </InlineForm>
          <InlineForm title={"Nama"}>
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"Nama Perusahaan"}
              value={name}
              onChange={(el) => setName(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Email"}>
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"Email"}
              value={email}
              onChange={(el) => setEmail(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Telp"}>
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"Telp"}
              value={phone}
              onChange={(el) => setPhone(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Fax"}>
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"Fax"}
              value={fax}
              onChange={(el) => setFax(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Alamat"} style={{ alignItems: "start" }}>
            <textarea
              rows={5}
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              placeholder={"Alamat"}
              value={address}
              onChange={(el) => setAddress(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Penanggung Jawab"}>
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"Penanggung Jawab"}
              value={contactPerson}
              onChange={(el) => setContactPerson(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Jabatan"}>
            <input
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              placeholder={"Jabatan"}
              value={contactPersonPosition}
              onChange={(el) => setContactPersonPosition(el.target.value)}
            />
          </InlineForm>
          <InlineForm title={"Pajak"}>
            <select
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              value={taxId}
              onChange={(el) => setTaxId(el.target.value)}
            >
              <option value="">Pilih Pajak</option>
              {taxes.map((e) => (
                <option key={e.uuid} value={e.uuid}>
                  {e.name}
                </option>
              ))}
            </select>
          </InlineForm>

          <InlineForm title={"Harga Termasuk Pajak"}>
            <Switch
              checked={priceTaxInclude}
              onChange={setPriceTaxInclude}
              className={`${
                priceTaxInclude ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Harga Termasuk Pajak</span>
              <span
                aria-hidden="true"
                className={`${
                  priceTaxInclude ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </InlineForm>
          <InlineForm title={"Check Stok"}>
            <Switch
              checked={checkStock}
              onChange={setCheckStock}
              className={`${
                checkStock ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Check Stok</span>
              <span
                aria-hidden="true"
                className={`${
                  checkStock ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </InlineForm>
          <InlineForm title={"Gudang"}>
            <SelectPicker
              data={stores.map((e) => ({ label: e.name, value: e.uuid }))}
              value={defaultStoreId}
              onChange={(val) => setDefaultStoreId(val!)}
              placeholder="Pilih Gudang"
              searchable={false}
              cleanable={false}
              block
            />
          </InlineForm>
          <InlineForm title={"Akun Penjualan"}>
            <SelectPicker
              block
              data={incomeAccounts.map((e) => ({
                label: e.name,
                value: e.uuid,
              }))}
              value={defaultSaleAccountId}
              onChange={(val) => setDefaultSaleAccountId(val!)}
              placeholder="Pilih Akun"
              onSearch={(val) => {
                if (val) {
                  getAccounts({
                    page: 1,
                    limit: 10,
                    type: "Income",
                    search: val,
                  })
                    .then((v) => v.json())
                    .then((v) => {
                      setIncomeAccounts(v.data);
                    });
                }
              }}
            />
          </InlineForm>
          <InlineForm title={"Kategori Harga"}>
            <SelectPicker
              data={allPriceCategories.map((e) => ({
                label: e.name,
                value: e.uuid,
              }))}
              value={defaultPriceCategoryId}
              onChange={(val) => setDefaultPriceCategoryId(val!)}
              placeholder="Pilih Kategori Harga"
              searchable={false}
              cleanable={false}
              block
            />
          </InlineForm>
          <InlineForm title={"Order Flow"}>
            <SelectPicker
              data={["Regular", "Resto"].map((e) => ({
                label: e,
                value: e,
              }))}
              value={orderFlow}
              onChange={(val) => setOrderFlow(val!)}
              placeholder="Order Flow"
              searchable={false}
              cleanable={false}
              block
            />
          </InlineForm>
          <InlineForm title={"Denah Pemesanan"}>
            <Switch
              checked={isTableMapping}
              onChange={setIsTableMapping}
              className={`${
                isTableMapping ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Denah Pemesanan</span>
              <span
                aria-hidden="true"
                className={`${
                  isTableMapping ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </InlineForm>
          <InlineForm title={"Station Aktif"}>
            <Switch
              checked={isMerchantStation}
              onChange={setIsMerchantStation}
              className={`${
                isMerchantStation ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Station Aktif</span>
              <span
                aria-hidden="true"
                className={`${
                  isMerchantStation ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </InlineForm>
          <InlineForm title={"QR Menu"}>
            <Switch
              checked={isQrMenuEnabled}
              onChange={setIsQrMenuEnabled}
              className={`${
                isQrMenuEnabled ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">QR Menu</span>
              <span
                aria-hidden="true"
                className={`${
                  isQrMenuEnabled ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </InlineForm>
          {isQrMenuEnabled && (
            <InlineForm title={"QR Menu Warna #1"}>
              <input
                type="color"
                value={qrMenuMainColor}
                onChange={(e) => {
                  setQrMenuMainColor(e.target.value);
                }}
              />
            </InlineForm>
          )}
          {isQrMenuEnabled && (
            <InlineForm title={"QR Menu Warna #2"}>
              <input
                type="color"
                value={qrMenuSecondaryColor}
                onChange={(e) => {
                  setQrMenuSecondaryColor(e.target.value);
                }}
              />
            </InlineForm>
          )}
          <InlineForm title={"Footer Message"} style={{ alignItems: "start" }}>
            <textarea
              rows={5}
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              placeholder={"Footer Message"}
              value={message}
              onChange={(el) => setMessage(el.target.value)}
            />
          </InlineForm>

          <InlineForm title={"Qr Message"} style={{ alignItems: "start" }}>
            <textarea
              rows={5}
              className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              placeholder={"Qr Message"}
              value={messageToQr}
              onChange={(el) => setMessageToQr(el.target.value)}
            />
          </InlineForm>
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => merchantUpdate()}
            >
              <FolderIcon className=" h-5 w-5 mr-2" aria-hidden="true" /> Simpan
            </button>
          </div>
        </Panel>
      </div>
      <div className="col-span-2">
        <Panel header="Pembayaran" bordered className="mb-8">
          {(merchant?.payment_providers ?? []).map((e) => (
            <InlineForm key={e.provider} title={e.provider}>
              <SelectPicker
                data={assetAccounts.map((e) => ({
                  label: e.name,
                  value: e.uuid,
                }))}
                value={
                  (merchant?.payment_providers ?? []).find(
                    (p) => p.provider == e.provider
                  )?.account_destination_id
                }
                onChange={(value) => {
                  let providers = [...merchant!.payment_providers];
                  providers = providers.map((p) => {
                    if (p.provider == e.provider) {
                      p.account_destination_id = value!;
                    }
                    return p;
                  });
                  setMerchant({
                    ...merchant!,
                    payment_providers: [...providers],
                  });
                }}
                placeholder="Pilih Akun"
                searchable={false}
                cleanable={false}
                block
              />
            </InlineForm>
          ))}
          <InlineForm title={"QRIS Statik"}>
            <div onClick={() => fileRef3.current?.click()}>
              {qrisPictureURL ? (
                <img src={qrisPictureURL} className="  object-cover w-60" />
              ) : (
                <CameraIcon className="w-10" />
              )}
            </div>
          </InlineForm>
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => merchantUpdate()}
            >
              <FolderIcon className=" h-5 w-5 mr-2" aria-hidden="true" /> Simpan
            </button>
          </div>
        </Panel>
        {isMerchantStation && (
          <Panel
            header={
              <Stack justifyContent="space-between">
                <span>Merchant Station</span>
                <div className="flex flex-row gap-2">
                  <button
                    type="button"
                    className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => setModalStationOpen(true)}
                  >
                    <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" />{" "}
                    Station
                  </button>
                </div>
              </Stack>
            }
            bordered
            className="mb-8"
          >
            <CustomTable
              headers={["#", "Nama", "Keterangan", ""]}
              headerClasses={[]}
              datasets={stations.map((e) => ({
                cells: [
                  { data: stations.indexOf(e) + 1 },
                  {
                    data: (
                      <div className="flex flex-row gap-2">
                        <Avatar size={32} name={e.name} url={e.picture} />
                        <span>{e.name}</span>
                      </div>
                    ),
                  },
                  { data: e.description },
                  {
                    data: (
                      <TrashIcon
                        className="text-red-400"
                        onClick={() => {
                          confirmDelete(() => {
                            deleteStation(e.uuid).then(() => {
                              getPosStation()
                                .then((v) => v.json())
                                .then((v) => {
                                  setStations(v.data);
                                });
                            });
                          });
                        }}
                      />
                    ),
                  },
                ],
              }))}
            />
          </Panel>
        )}
      </div>
    </div>
  );

  const accountMachine = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Akun</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalAccountOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Tambah
                Akun
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["#", "Nama", "Tipe", "Kategori/Grup", ""]}
            headerClasses={[]}
            datasets={merchantAccounts.map((e) => {
              let data: TableRecord = {
                cells: [
                  { data: merchantAccounts.indexOf(e) + 1 },
                  { data: <div>{e.name}</div> },
                  { data: ACCOUNT_LABELS[e.type] },
                  {
                    data: (
                      <div>
                        <dl className="text-xs">
                          <dt className="font-[600]">Kategori</dt>
                          <dd>{e.category}</dd>
                          <dt className="font-[600]">Grup Arus Kas</dt>
                          <dd>{e.cashflow_group_label}</dd>
                          <dt className="font-[600]">Subgrup Arus Kas</dt>
                          <dd>{e.cashflow_subgroup_label}</dd>
                        </dl>
                      </div>
                    ),
                  },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantAccount(e.uuid)
                                .then(() => {
                                  getMerchantAccounts()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setMerchantAccounts(v.data);
                                    });
                                })
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
              return data;
            })}
          />
        </Panel>
      </div>
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Mesin Kasir</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalMachineOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Tambah
                Mesin
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["#", "Nama", "Deposit", ""]}
            headerClasses={[]}
            datasets={machines.map((e) => {
              let data: TableRecord = {
                cells: [
                  { data: machines.indexOf(e) + 1 },
                  {
                    data: (
                      <div
                        onClick={() => {
                          setSelectedMachine(e);
                          setInputMachineName(e.name);
                          setInputDeposit(e.deposit);
                          setTimeout(() => {
                            setModalMachineOpen(true);
                          }, 300);
                        }}
                        className=" hover:font-medium"
                      >
                        {e.name}
                      </div>
                    ),
                  },
                  { data: money(e.deposit) },

                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMachine(e.uuid)
                                .then((v) =>
                                  getMerchantMachines()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setMachines(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
              return data;
            })}
          />
        </Panel>
      </div>
    </div>
  );

  const categories = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Kategori Produk</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalProductCategoryOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Tambah
                Kategori
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["Nama", "Kode", "Keterangan", ""]}
            headerClasses={[]}
            datasets={productCategories.map((e) => {
              return {
                cells: [
                  { data: e.name },
                  { data: e.code },
                  { data: e.description },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantProductCategory(e.uuid)
                                .then((v) =>
                                  getMerchantProductCategories()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setProductCategories(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Kategori Harga</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalPriceCategoryOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Tambah
                Kategori
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["Nama", "Kode", "Keterangan", ""]}
            headerClasses={[]}
            datasets={priceCategories.map((e) => {
              return {
                cells: [
                  { data: e.name },
                  { data: e.code },
                  { data: e.description },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantPriceCategory(e.uuid)
                                .then((v) =>
                                  getMerchantPriceCategories()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setPriceCategories(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
    </div>
  );

  const room = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Ruangan</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalRoomOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Tambah
                Ruangan
              </button>
            </Stack>
          }
          bordered
        >
          <CustomTable
            headers={["#", "Nama", "Keterangan", "Dimesi", "Lantai", ""]}
            headerClasses={[]}
            datasets={rooms.map((e) => {
              return {
                cells: [
                  { data: rooms.indexOf(e) + 1 },
                  { data: e.name },
                  { data: e.description },
                  { data: `${e.width} x ${e.length}` },
                  { data: e.floor },
                  {
                    data: (
                      <div className="flex flex-row items-center gap-2">
                        {isTableMapping && (
                          <VscEditorLayout
                            className="text-blue-400"
                            onClick={() => nav(`/room/${e.uuid}`)}
                          />
                        )}

                        <MdOutlineLocalDining
                          className="text-blue-400"
                          onClick={() => {
                            // console.log()
                            setSelectedRoom(
                              rooms.find((d) => e.uuid == d.uuid)
                            );
                          }}
                        />
                        <TrashIcon
                          className="w-5 text-red-600"
                          onClick={() => {
                            confirmDelete(() => {
                              setIsLoading(true);
                              deleteRoom(e.uuid)
                                .then((v) =>
                                  getPosRoom()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setRooms(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            });
                          }}
                        />
                      </div>
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
      <div className="col-span-2"></div>
    </div>
  );
  const productService = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-4">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Produk</span>
              <div className="flex flex-row gap-2">
                <button
                  type="button"
                  className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() => setModalProductOpen(true)}
                >
                  <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" />{" "}
                  Produk
                </button>
                <button
                  type="button"
                  className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() => {
                    confirmDelete(
                      () => {
                        setIsLoading(true);
                        addMerchantAllProduct()
                          .then((v) => {
                            getMerchantProducts()
                              .then((v) => v.json())
                              .then((v) => {
                                setProducts(v.data);
                              });
                          })
                          .catch(errorToast)
                          .finally(() => setIsLoading(false));
                      },
                      null,
                      "Anda yakin untuk menambahkan semua produk?",
                      "Tambahkan Semua"
                    );
                  }}
                >
                  <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" />{" "}
                  Semua Produk
                </button>
              </div>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["#", "Nama Produk", "Unit", "Station", "Catatan", ""]}
            headerClasses={[
              "text-center w-6",
              "text-left",
              "text-left",
              "",
              "w-60",
              "",
            ]}
            datasets={products.map((e) => {
              return {
                cells: [
                  { data: products.indexOf(e) + 1 },
                  {
                    data: (
                      <div
                        className="flex justify-between items-center hover:font-medium"
                        style={{ width: "100%" }}
                        onClick={() => nav(`/product/${e.uuid}`)}
                      >
                        {e.name}
                      </div>
                    ),
                  },
                  { data: e.unit_name },
                  { data: e.merchant_station_name },
                  {
                    data: (
                      <div>
                        {e.notes_template?.map((e, i) => (
                          <Tag key={i}>{e}</Tag>
                        ))}
                      </div>
                    ),
                  },
                  {
                    data: (
                      <div className="flex justify-end  items-center">
                        <div
                          onClick={() => {
                            pinnedMerchantProduct(e.uuid).then((e) => {
                              getMerchantProducts()
                                .then((v) => v.json())
                                .then((v) => {
                                  setProducts(v.data);
                                });
                            });
                          }}
                        >
                          {e.is_pinned ? (
                            <CheckCircleIcon className="w-5 h-5 text-green-700 mr-2" />
                          ) : (
                            <XCircleIcon className="w-5 h-5 text-red-700 mr-2" />
                          )}
                        </div>
                        <TrashIcon
                          className="w-5 text-red-600"
                          onClick={() => {
                            Swal.fire({
                              title: "Anda Yakin",
                              text: "Anda tidak akan dapat mengembalikan proses ini!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Ya, Hapus!",
                              cancelButtonText: "Batal",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setIsLoading(true);
                                deleteMerchantProduct(e.uuid)
                                  .then((v) =>
                                    getMerchantProducts()
                                      .then((v) => v.json())
                                      .then((v) => {
                                        setProducts(v.data);
                                      })
                                  )
                                  .finally(() => setIsLoading(false));
                              }
                            });
                          }}
                        />
                        <PiGear
                          size={18}
                          className="ml-2"
                          onClick={() => setSelectedProduct(e)}
                        />
                      </div>
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
      {/* <div className='col-span-2'>
      <Panel
        header={
          <Stack justifyContent="space-between">
            <span>Jasa</span>
            <button
              type="button"
              className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => setModalServiceOpen(true)}
            >
              <PlusIcon
                className=" h-3 w-3 mr-1"
                aria-hidden="true" />  Tambah Jasa
            </button>
          </Stack>
        }
        bordered className='mb-8' >
      </Panel>
    </div> */}
    </div>
  );

  const storeCashier = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Gudang</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalStoreOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Gudang
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["Nama Gudang", "Kontak Person", ""]}
            headerClasses={[]}
            datasets={merchantStores.map((e) => {
              return {
                cells: [
                  {
                    data: e.name,
                  },
                  {
                    data: e.contact_person,
                  },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantStore(e.uuid!)
                                .then((v) =>
                                  getMerchantStores()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setMerchantStores(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Kasir</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalMerchantUserOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Kasir
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["Nama", "Email", ""]}
            headerClasses={[]}
            datasets={merchantUsers.map((e) => {
              return {
                cells: [
                  {
                    data: e.full_name,
                  },
                  {
                    data: e.email,
                  },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantUser(e.uuid!)
                                .then((v) =>
                                  getMerchantUsers()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setMerchantUsers(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
    </div>
  );

  const taxDiscount = () => (
    <div className="grid gap-x-8 gap-y-4 sm:gap-y-0 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2">
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Pajak</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalTaxOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Pajak
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["Pajak", "Nilai", ""]}
            headerClasses={[]}
            datasets={merchantTaxes.map((e) => {
              return {
                cells: [
                  {
                    data: e.name,
                  },
                  {
                    data: `${e.amount}%`,
                  },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantTax(e.uuid!)
                                .then((v) =>
                                  getMerchantTaxes()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setMerchantTaxes(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
      <div className="col-span-2">
        <Panel
          header={
            <Stack justifyContent="space-between">
              <span>Diskon</span>
              <button
                type="button"
                className="mr-2 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => setModalDiscountOpen(true)}
              >
                <PlusIcon className=" h-3 w-3 mr-1" aria-hidden="true" /> Diskon
              </button>
            </Stack>
          }
          bordered
          className="mb-8"
        >
          <CustomTable
            headers={["Nama", "Berlaku", "Keterangan", "Nilai", ""]}
            headerClasses={[]}
            datasets={merchantDiscounts.map((e) => {
              return {
                cells: [
                  {
                    data: e.name,
                  },
                  {
                    data: (
                      <small className="flex flex-col justify-center">
                        <Moment format="DD/MM/YYYY">{e.start_date}</Moment>
                        <span className="text-center">s/d</span>
                        <Moment format="DD/MM/YYYY">{e.end_date}</Moment>
                      </small>
                    ),
                  },
                  {
                    data: e.description,
                  },
                  {
                    data: e.type == 1 ? `${e.percent}%` : money(e.amount),
                  },
                  {
                    data: (
                      <TrashIcon
                        className="w-5 text-red-600"
                        onClick={() => {
                          Swal.fire({
                            title: "Anda Yakin",
                            text: "Anda tidak akan dapat mengembalikan proses ini!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Ya, Hapus!",
                            cancelButtonText: "Batal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setIsLoading(true);
                              deleteMerchantDiscount(e.uuid!)
                                .then((v) =>
                                  getMerchantDiscounts()
                                    .then((v) => v.json())
                                    .then((v) => {
                                      setMerchantDiscounts(v.data);
                                    })
                                )
                                .finally(() => setIsLoading(false));
                            }
                          });
                        }}
                      />
                    ),
                    className: "w-4 text-right",
                  },
                ],
                className: "last:border-0 hover:bg-gray-50",
              };
            })}
          />
        </Panel>
      </div>
    </div>
  );

  return (
    <div className="flex-1 mb-5 col-span-1 mt-5 rounded-xl py-6 px-6 transition-all hover:shadow-lg sm:p-4 lg:px-4 xl:px-6 bg-white cursor-pointer">
      {info()}
      {productService()}
      {storeCashier()}
      {categories()}
      {accountMachine()}
      {taxDiscount()}
      {room()}
      <ModalAccount
        isOpen={modalAccountOpen}
        setIsOpen={setModalAccountOpen}
        onClick={(val: Account) => {
          addMerchantAccount({
            account_id: setNullString(val.uuid),
            type: val.type,
          }).then((v) =>
            getMerchantAccounts()
              .then((v) => v.json())
              .then((v) => {
                setMerchantAccounts(v.data);
              })
          );
        }}
      />
      <ModalCustom
        title="Form Mesin"
        isOpen={modalMachineOpen}
        setIsOpen={setModalMachineOpen}
        onClose={() => {
          setInputDeposit(0);
          setInputMachineName("");
          setSelectedMachine(null);
        }}
      >
        <div className="flex h-full flex-col">
          <div className="flex-1">
            <InlineForm title={"Nama Mesin"} className="mt-4">
              <input
                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
                placeholder={"contoh: Casio CX-100"}
                value={inputMachineName}
                onChange={(el) => setInputMachineName(el.target.value)}
              />
            </InlineForm>
            <InlineForm title={"Deposit"}>
              <CurrencyInput
                className="bg-white appearance-none border border-gray-200 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                groupSeparator="."
                decimalSeparator=","
                placeholder="Deposit"
                defaultValue={inputDeposit}
                onValueChange={(value, _, values) => {
                  setInputDeposit(values?.float ?? 0);
                }}
              />
            </InlineForm>
          </div>

          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={async () => {
                try {
                  setIsLoading(true);
                  if (selectedMachine) {
                    await updateMachine(selectedMachine!.uuid, {
                      name: inputMachineName,
                      deposit: inputDeposit,
                    });
                  } else {
                    await addMachine({
                      name: inputMachineName,
                      deposit: inputDeposit,
                    });
                  }

                  getMerchantMachines()
                    .then((v) => v.json())
                    .then((v) => {
                      setMachines(v.data);
                    });
                } catch (error) {
                  Swal.fire("Perhatian", `${error}`, "error");
                } finally {
                  setInputDeposit(0);
                  setInputMachineName("");
                  setModalMachineOpen(false);
                  setSelectedMachine(null);
                  setIsLoading(false);
                }
              }}
            >
              <FolderIcon className=" h-5 w-5 mr-2" aria-hidden="true" /> Simpan
            </button>
          </div>
        </div>
      </ModalCustom>
      <Modal
        open={modalProductCategoryOpen}
        onClose={() => setModalProductCategoryOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Kategori Produk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Kategori"} className="mt-4">
            <SelectPicker
              data={allProductCategories.map((e) => ({
                label: e.name,
                value: e.uuid,
              }))}
              block
              placeholder="Pilih Kategori"
              onChange={(value) => {
                addMerchantProductCategory({
                  product_category_id: setNullString(value),
                })
                  .then((v) => {
                    getMerchantProductCategories()
                      .then((v) => v.json())
                      .then((v) => {
                        setProductCategories(v.data);
                      });
                  })
                  .finally(() => setModalProductCategoryOpen(false));
              }}
              searchable={false}
              cleanable={false}
            />
          </InlineForm>
        </Modal.Body>
      </Modal>

      <Modal
        open={modalPriceCategoryOpen}
        onClose={() => setModalPriceCategoryOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Kategori Harga</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Kategori"} className="mt-4">
            <SelectPicker
              data={allPriceCategories.map((e) => ({
                label: e.name,
                value: e.uuid,
              }))}
              block
              placeholder="Pilih Kategori"
              onChange={(value) => {
                addMerchantPriceCategory({
                  price_category_id: setNullString(value),
                })
                  .then((v) => {
                    getMerchantPriceCategories()
                      .then((v) => v.json())
                      .then((v) => {
                        setPriceCategories(v.data);
                      });
                  })
                  .finally(() => setModalPriceCategoryOpen(false));
              }}
              searchable={false}
              cleanable={false}
            />
          </InlineForm>
        </Modal.Body>
      </Modal>
      <ModalCustom
        title="Pilih Produk"
        isOpen={modalProductOpen}
        setIsOpen={setModalProductOpen}
        onClose={() => {
          setModalProductOpen(false);
          setProductSelect(null);
          setUnitSelect(null);
          setIsProductPinned(false);
        }}
      >
        <div className="flex h-full flex-col">
          <div className="flex-1">
            <InlineForm title={"Produk"}>
              <Select<SelectOption, false>
                styles={colourStyles}
                options={allProducts.map((e) => {
                  return { value: e.uuid, label: e.name };
                })}
                value={productSelect}
                onChange={(option: SingleValue<SelectOption>): void => {
                  let selected = allProducts.find(
                    (e) => e.uuid == option?.value
                  );
                  setProductSelect(option);
                  setUnits(selected?.units ?? []);
                }}
                onInputChange={(val) => {
                  getProducts({ page: 1, limit: 10, isSell: true, search: val })
                    .then((v) => v.json())
                    .then((v) => {
                      setAllProducts(v.data);
                    });
                }}
              />
            </InlineForm>
            <InlineForm title={"Unit"}>
              <Select<SelectOption, false>
                styles={colourStyles}
                options={units.map((e) => {
                  return { value: e.uuid, label: e.name };
                })}
                value={unitSelect}
                onChange={(option: SingleValue<SelectOption>): void => {
                  let selected = allProducts.find(
                    (e) => e.uuid == option?.label
                  );
                  setUnitSelect(option);
                }}
              />
            </InlineForm>
            <InlineForm title={"Pinned"} className="mt-4">
              <Switch
                checked={isProductPinned}
                onChange={setIsProductPinned}
                className={`${
                  isProductPinned ? "bg-blue-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Pinned</span>
                <span
                  aria-hidden="true"
                  className={`${
                    isProductPinned ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </InlineForm>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => {
                let selected = allProducts.find(
                  (e) => e.uuid == productSelect?.value
                );
                let selectedUnit = units.find(
                  (e) => e.uuid == unitSelect?.value
                );
                addMerchantProduct({
                  product_id: setNullString(selected?.uuid),
                  product_category_id: setNullString(
                    selected?.product_category_id
                  ),
                  merchant_station_id: setNullString(),
                  unit_id: setNullString(selectedUnit?.uuid),
                  unit_name: selectedUnit?.name ?? "",
                  unit_value: selectedUnit?.value ?? 0,
                  notes_template: "[]",
                  is_pinned: isProductPinned,
                }).then((v) =>
                  getMerchantProducts()
                    .then((v) => v.json())
                    .then((v) => {
                      setProducts(v.data);
                      setModalProductOpen(false);
                      setProductSelect(null);
                      setUnitSelect(null);
                      setIsProductPinned(false);
                    })
                );
              }}
            >
              <FolderIcon className=" h-5 w-5 mr-2" aria-hidden="true" /> Simpan
            </button>
          </div>
        </div>
      </ModalCustom>
      <ModalSearch
        isOpen={modalStoreOpen}
        setIsOpen={setModalStoreOpen}
        onChange={(val) => {
          getStores({ page: 1, limit: 10, search: val })
            .then((v) => v.json())
            .then((v) => {
              setAllStores(v.data);
            });
        }}
        onClose={() => {
          setModalStoreOpen(false);
        }}
      >
        <ul role="list" className="divide-y divide-gray-100">
          {allStores.map((e) => (
            <li
              onClick={() => {
                setModalStoreOpen(false);
                addMerchantStore({ store_id: setNullString(e.uuid) }).then(
                  (v) => {
                    getMerchantStores()
                      .then((v) => v.json())
                      .then((v) => {
                        setMerchantStores(v.data);
                      });
                  }
                );
              }}
              key={e.uuid}
              className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
            >
              <Avatar marginRight={0} name={e.name} url={""} />
              <div className="min-w-0">
                <p className="text-sm font-semibold leading-6   ">{e.name}</p>
              </div>
            </li>
          ))}
        </ul>
      </ModalSearch>
      <ModalSearch
        isOpen={modalMerchantUserOpen}
        setIsOpen={setModalMerchantUserOpen}
        onChange={(val) => {
          getCompanyMembers()
            .then((v) => v.json())
            .then((v) => {
              setAllMembers(v.data);
            });
        }}
        onClose={() => {
          setModalMerchantUserOpen(false);
        }}
      >
        <ul role="list" className="divide-y divide-gray-100">
          {allMembers.map((e) => (
            <li
              onClick={() => {
                setModalMerchantUserOpen(false);
                addMerchantUser({ cashier_id: setNullString(e.uuid) }).then(
                  (v) => {
                    getMerchantUsers()
                      .then((v) => v.json())
                      .then((v) => {
                        setMerchantUsers(v.data);
                      });
                  }
                );
              }}
              key={e.uuid}
              className="flex items-center gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
            >
              <Avatar marginRight={0} name={e.full_name} url={""} />
              <div className="min-w-0">
                <p className="text-sm font-semibold leading-6   ">
                  {e.full_name}
                </p>
                <p className="text-xs font-base leading-6   ">{e.email}</p>
              </div>
            </li>
          ))}
        </ul>
      </ModalSearch>
      <Modal open={modalTaxOpen} onClose={() => setModalTaxOpen(false)}>
        <Modal.Header>
          <Modal.Title>Tambah Pajak</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Pajak"} className="mt-4">
            <SelectPicker
              block
              data={taxes.map((e) => ({
                value: e.uuid,
                label: `${e.name} (${e.amount}%)`,
              }))}
              onChange={(val) => {
                addMerchantTax({ tax_id: setNullString(val) })
                  .then((v) => {
                    getMerchantTaxes()
                      .then((v) => v.json())
                      .then((v) => {
                        setMerchantTaxes(v.data);
                      });
                  })
                  .finally(() => setModalTaxOpen(false));
              }}
            />
          </InlineForm>
        </Modal.Body>
      </Modal>
      <Modal
        open={modalDiscountOpen}
        onClose={() => setModalDiscountOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Tambah Diskon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Diskon"} className="mt-4">
            <SelectPicker
              block
              data={allDiscounts.map((e) => ({
                value: e.uuid,
                label: `${e.name} (${
                  e.type == 1 ? `${e.percent}%` : money(e.amount)
                })`,
              }))}
              onChange={(val) => {
                addMerchantDiscount({ discount_id: setNullString(val) })
                  .then((v) => {
                    getMerchantDiscounts()
                      .then((v) => v.json())
                      .then((v) => {
                        setMerchantDiscounts(v.data);
                      });
                  })
                  .finally(() => setModalDiscountOpen(false));
              }}
            />
          </InlineForm>
        </Modal.Body>
      </Modal>
      <Drawer
        open={selectedProduct != undefined}
        onClose={() => setSelectedProduct(undefined)}
      >
        <Drawer.Header>
          <Drawer.Title>{selectedProduct?.name}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setSelectedProduct(undefined)}>Batal</Button>
            <Button
              onClick={() => {
                updateMerchantProduct(selectedProduct!.uuid!, {
                  notes_template: JSON.stringify(
                    selectedProduct!.notes_template
                  ),
                  product_id: setNullString(selectedProduct!.uuid!),
                  product_category_id: setNullString(
                    selectedProduct!.product_category_id!
                  ),
                  merchant_station_id: setNullString(
                    selectedProduct!.merchant_station_id
                  ),
                  unit_id: setNullString(selectedProduct!.unit_id!),
                  unit_name: selectedProduct!.unit_name,
                  unit_value: selectedProduct!.unit_value,
                  is_pinned: selectedProduct!.is_pinned,
                })
                  .then((v) => {
                    setIsLoading(true);
                    getMerchantProducts()
                      .then((v) => v.json())
                      .then((v) => {
                        setProducts(v.data);
                        setSelectedProduct(undefined);
                      });
                  })
                  .catch(errorToast)
                  .finally(() => setIsLoading(false));
              }}
              appearance="primary"
            >
              Simpan
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body
          style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 0 }}
        >
          <InlineForm title={"Produk"} className="mt-4">
            {selectedProduct?.name}
          </InlineForm>
          <InlineForm title={"Unit"} className="mt-4">
            {selectedProduct?.unit_name}
          </InlineForm>
          <InlineForm title={"Station"} className="mt-4">
            <SelectPicker
              block
              data={stations}
              value={selectedProduct?.merchant_station_id}
              labelKey="name"
              valueKey="uuid"
              onChange={(val) => {
                setSelectedProduct({
                  ...selectedProduct!,
                  merchant_station_id: val!,
                });
              }}
            />
          </InlineForm>
          <InlineForm title={"Pinned"}>
            <Toggle
              checked={selectedProduct?.is_pinned}
              onChange={(val) => {
                setSelectedProduct({
                  ...selectedProduct!,
                  is_pinned: val,
                });
              }}
            />
          </InlineForm>
          <InlineForm title={"Template Catatan"} className="mt-4">
            <TagPicker
              creatable
              block
              data={(selectedProduct?.notes_template ?? []).map((e) => ({
                value: e,
                label: e,
              }))}
              value={selectedProduct?.notes_template ?? []}
              onChange={(val) => {
                setSelectedProduct({
                  ...selectedProduct!,
                  notes_template: val,
                });
              }}
              onCreate={(value, item) => {
                console.log(value, item);
                setSelectedProduct({
                  ...selectedProduct!,
                  notes_template: value,
                });
              }}
            />
          </InlineForm>
        </Drawer.Body>
      </Drawer>
      <Modal open={modalStationOpen} onClose={() => setModalStationOpen(false)}>
        <Modal.Header>
          <Modal.Title>Tambah Station</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Picture"} className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center items-center w-full rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-400 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
              onClick={() => fileRef.current?.click()}
            >
              {stationPictureURL ? (
                <img
                  src={stationPictureURL}
                  className="  object-cover w-full"
                />
              ) : (
                <CameraIcon className="w-10" />
              )}
            </button>
          </InlineForm>
          <InlineForm title={"Nama Station"} className="mt-4">
            <Input
              className="input-form"
              placeholder="Nama Station"
              value={stationName}
              onChange={(val) => setStationName(val)}
            />
          </InlineForm>
          <InlineForm title={"Keterangan"} className="mt-4">
            <Input
              className="input-form"
              as="textarea"
              placeholder="Keterangan"
              value={stationDesc}
              onChange={(val) => setStationDesc(val)}
            />
          </InlineForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsLoading(true);
              addPosStation({
                name: stationName,
                description: stationDesc,
                picture: stationPicturePath,
              })
                .then((v) => {
                  setModalStationOpen(false);
                  setStationName("");
                  setStationDesc("");
                  setStationPictureURL("");
                  setStationPicturePath("");
                  getPosStation()
                    .then((v) => v.json())
                    .then((v) => {
                      setStations(v.data);
                    });
                })
                .catch(errorToast)
                .finally(() => setIsLoading(false));
            }}
            appearance="primary"
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={modalRoomOpen} onClose={() => setModalRoomOpen(false)}>
        <Modal.Header>
          <Modal.Title>Tambah Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InlineForm title={"Picture"} className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center items-center w-full rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-400 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
              onClick={() => fileRef2.current?.click()}
            >
              {roomPictureURL ? (
                <img src={roomPictureURL} className="  object-cover w-full" />
              ) : (
                <CameraIcon className="w-10" />
              )}
            </button>
          </InlineForm>
          <InlineForm title={"Nama Ruangan"} className="mt-4">
            <Input
              className="input-form"
              placeholder="Nama Ruangan"
              value={roomName}
              onChange={(val) => setRoomName(val)}
            />
          </InlineForm>
          <InlineForm title={"Keterangan"} className="mt-4">
            <Input
              className="input-form"
              as="textarea"
              placeholder="Keterangan"
              value={roomDesc}
              onChange={(val) => setRoomDesc(val)}
            />
          </InlineForm>
          <InlineForm title={"Panjang"} className="mt-4">
            <Input
              className="input-form"
              type="number"
              placeholder="Panjang"
              value={roomLength}
              onChange={(val) => setRoomLength(Number(val))}
            />
          </InlineForm>
          <InlineForm title={"Lebar"} className="mt-4">
            <Input
              className="input-form"
              type="number"
              placeholder="Lebar"
              value={roomWidth}
              onChange={(val) => setRoomWidth(Number(val))}
            />
          </InlineForm>
          <InlineForm title={"Lantai"} className="mt-4">
            <Input
              className="input-form"
              type="number"
              placeholder="Lantai"
              value={roomFloor}
              onChange={(val) => setRoomFloor(Number(val))}
            />
          </InlineForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsLoading(true);
              addPosRoom({
                name: roomName,
                description: roomDesc,
                length: roomLength,
                width: roomWidth,
                floor: roomFloor,
                picture: roomPicturePath,
              })
                .then((v) => {
                  setModalRoomOpen(false);
                  setRoomName("");
                  setRoomDesc("");
                  setRoomPictureURL("");
                  setRoomPicturePath("");
                  getPosRoom()
                    .then((v) => v.json())
                    .then((v) => {
                      setRooms(v.data);
                    });
                })
                .catch(errorToast)
                .finally(() => setIsLoading(false));
            }}
            appearance="primary"
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={selectedRoom != undefined}
        onClose={() => setSelectedRoom(undefined)}
      >
        <Modal.Header>
          <Modal.Title>{selectedRoom?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!modeFormTable && (
            <>
              <div className="flex flex-row justify-end mb-4">
                <Button
                  onClick={() => {
                    setModeFormTable(true);
                    setSelectedShape({
                      id: randomStr(6),
                      strokeWidth: 0.5,
                      type: "rect",
                      text: ` #${(selectedRoom?.tables ?? []).length + 1}`,
                      x: 100,
                      y: 100,
                      width: 100,
                      height: 100,
                      radius: 30,
                      fill: "#dedede",
                      stroke: "#333333",
                    });
                  }}
                  appearance="primary"
                  className="mb-4"
                >
                  Tambah Meja
                </Button>
              </div>
              <table className="w-full h-full text-sm text-left rtl:text-right text-gray-700">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50  border-b ">
                  <tr>
                    <th scope="col" className="py-3 px-6">
                      Meja
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Warna
                    </th>
                    <th scope="col" className="py-3 px-6">
                      QR
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(selectedRoom?.tables ?? []).map((e) => (
                    <tr key={e.id}>
                      <td scope="col" className="py-3 px-6">
                        {e.text}
                      </td>
                      <td scope="col" className="py-3 px-6">
                        {e.fill}
                      </td>
                      <td>
                        <div
                          className={`p-4 w-32 flex flex-col justify-center items-center`}
                        >
                          <QRCode
                            size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            id={`qr-${e.id}`}
                            value={`${process.env.REACT_APP_FRONTEND_URL}/qr-menu/${selectedRoom?.uuid}/${e.id}`}
                            viewBox={`0 0 256 256`}
                          />

                          <Button
                            size="sm"
                            className="mt-4"
                            onClick={() => {
                              const svg = document.getElementById(`qr-${e.id}`);
                              const svgData =
                                new XMLSerializer().serializeToString(svg!);
                              const canvas = document.createElement("canvas");
                              const ctx = canvas.getContext("2d");
                              const img = new Image();
                              img.onload = () => {
                                canvas.width = img.width + 20;
                                canvas.height = img.height + 20;
                                ctx!.fillStyle = "white";
                                ctx!.fillRect(
                                  0,
                                  0,
                                  canvas.width,
                                  canvas.height
                                );
                                ctx!.drawImage(img, 10, 10);
                                ctx!.strokeStyle = "white";
                                ctx!.lineWidth = 10;
                                ctx!.strokeRect(
                                  0,
                                  0,
                                  canvas.width,
                                  canvas.height
                                );
                                const pngFile = canvas.toDataURL("image/png");
                                const downloadLink =
                                  document.createElement("a");
                                downloadLink.download = `QRMenu-${e.id}`;
                                downloadLink.href = `${pngFile}`;
                                downloadLink.click();
                              };
                              img.src = `data:image/svg+xml;base64,${btoa(
                                svgData
                              )}`;
                              // const link = document.createElement("a");
                              // link.href = canvas.toDataURL("image/png");
                              // link.download = `${e.text}.png`;
                              // link.click();
                            }}
                          >
                            Download
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {modeFormTable && selectedShape && (
            <>
              <InlineForm title={"Text"}>
                <Input
                  type="text"
                  value={selectedShape?.text}
                  onChange={(e) => {}}
                />
              </InlineForm>

              <InlineForm title={"Warna"}>
                <input
                  type="color"
                  value={selectedShape.fill}
                  onChange={(e) => {}}
                />
              </InlineForm>
              <InlineForm title={"Warna Text"}>
                <input
                  type="color"
                  value={selectedShape.textColor}
                  onChange={(e) => {}}
                />
              </InlineForm>
              <InlineForm title={"Garis"}>
                <input
                  type="color"
                  value={selectedShape.stroke}
                  onChange={(e) => {}}
                />
              </InlineForm>
              <InlineForm title={"Tebal Garis"}>
                <Input
                  type="number"
                  value={selectedShape.strokeWidth}
                  onChange={(e) => {}}
                />
              </InlineForm>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {modeFormTable && (
            <>
              <Button
                appearance="primary"
                onClick={() => {
                  updatePosRoomDetail(selectedRoom?.uuid ?? "", {
                    ...room,
                    uuid: setNullString(selectedRoom?.uuid),
                    tables: JSON.stringify([
                      ...selectedRoom!.tables!,
                      selectedShape,
                    ]),
                  })
                    .then((v) => v.json())
                    .then((v) => {
                      successToast("Data berhasil disimpan");

                      setSelectedRoom((room) => {
                        return {
                          ...room!,
                          tables: [...room!.tables, selectedShape],
                        };
                      });
                      setTimeout(() => {
                        setSelectedShape(null);
                        setModeFormTable(false);
                      }, 300);
                    })
                    .catch(errorToast)
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              >
                Simpan
              </Button>
              <Button
                onClick={() => {
                  setSelectedShape(null);
                  setModeFormTable(false);
                }}
              >
                Batal
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <input
        value={""}
        type="file"
        id="file"
        accept="image/*"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={async (e) => {
          if (!modalStationOpen) return;
          try {
            setIsLoading(true);
            var resp = await fileUpload(`File/ImageUpload`, {
              image: e.target.files![0],
              dir: "avatar",
            });

            let respJson = await resp.json();
            setStationPicturePath(respJson.data.path);
            setStationPictureURL(respJson.data.url);
          } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error");
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <input
        value={""}
        type="file"
        id="file"
        accept="image/*"
        ref={fileRef2}
        style={{ display: "none" }}
        onChange={async (e) => {
          if (!modalRoomOpen) return;
          try {
            setIsLoading(true);
            var resp = await fileUpload(`File/ImageUpload`, {
              image: e.target.files![0],
              dir: "avatar",
            });

            let respJson = await resp.json();
            setRoomPicturePath(respJson.data.path);
            setRoomPictureURL(respJson.data.url);
          } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error");
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <input
        value={""}
        type="file"
        id="file"
        accept="image/*"
        ref={fileRef3}
        style={{ display: "none" }}
        onChange={async (e) => {
          try {
            setIsLoading(true);
            var resp = await fileUpload(`File/ImageUpload`, {
              image: e.target.files![0],
              dir: "avatar",
            });

            let respJson = await resp.json();
            setQrisPicturePath(respJson.data.path);
            setQrisPictureURL(respJson.data.url);
            merchantUpdate(respJson.data.path);
          } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error");
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <input
        value={""}
        type="file"
        id="file"
        accept="image/*"
        ref={fileRef4}
        style={{ display: "none" }}
        onChange={async (e) => {
          try {
            setIsLoading(true);
            var resp = await fileUpload(`File/ImageUpload`, {
              image: e.target.files![0],
              dir: "avatar",
            });

            let respJson = await resp.json();
            // setQrisPicturePath(respJson.data.path);
            setLogo(respJson.data.url);
            merchantUpdate(null, respJson.data.path);
          } catch (error) {
            Swal.fire("Perhatian", `${error}`, "error");
          } finally {
            setIsLoading(false);
          }
        }}
      />
    </div>
  );
};
export default CashierSetting;
