import { ReactNode, useEffect, useState, type FC } from "react";
import { MerchantOrder, MerchantSaleItem } from "../model/pos";
import { Merchant, TableOccupied } from "../model/merchant";
import { money } from "../utils/number";
import {
  checkDiscountByProductId,
  checkDiscounts,
  countDiscount,
  countGrandTotal,
  countItem,
  countItemByQty,
  countSubTotal,
  countTax,
} from "../utils/pos_helper";
import { Tax } from "../model/tax";
import { Button, Input, Modal, Panel, Tag } from "rsuite";
import { initials, parseVarian, setNullString } from "../utils/helper";
import { PiPlusBold } from "react-icons/pi";
import moment from "moment";
import { Discount } from "../model/discount";
import { BiTrash } from "react-icons/bi";
import OrderProduct from "./OrderProduct";
import { Product, VarianStock } from "../model/product";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import CurrencyInput from "react-currency-input-field";
import InlineForm from "./inline_form";
import Moment from "react-moment";
import Avatar from "./avatar";
import { errorToast, successToast } from "../utils/helper-ui";
import Swal from "sweetalert2";
import {
  cancelPosOrderItem,
  editPosOrderItem,
  getPosOrderDetail,
} from "../repositories/pos";

interface RestoOrderProps {
  merchant: Merchant;
  tax?: Tax | null;
  salesItems: MerchantSaleItem[];
  tableOccupied: TableOccupied | undefined;
  setTableOccupied: (val: TableOccupied | undefined) => void;
  discounts: Discount[];
  orders: MerchantOrder[];
  isLoading: boolean;
  selectedOrder: MerchantOrder | undefined;
  modalOrderOpen: boolean;
  resetOrders: () => void;
  setModalOrderOpen: (val: boolean) => void;
  setActiveKey: (val: string) => void;
  setSelectedOrder: (val: MerchantOrder) => void;
  sendOrder: () => void;
  delItem: (item: MerchantSaleItem) => void;
  editOrder: (order: MerchantOrder) => void;
  editItem: (
    item: MerchantSaleItem,
    qty: number,
    refresh?: boolean,
    discount?: Discount | null
  ) => void;
  addItem: (
    item: Product,
    varian?: VarianStock | null,
    discount?: Discount | null,
    orderItemQty?: number
  ) => void;
  splitOrder: (
    newCustomerName: string,
    splitBillItems: MerchantSaleItem[]
  ) => Promise<boolean>;
  pagination: ReactNode;
}

const RestoOrder: FC<RestoOrderProps> = ({
  merchant,
  tax,
  tableOccupied,
  salesItems,
  discounts,
  orders,
  isLoading,
  selectedOrder,
  setSelectedOrder,
  setTableOccupied,
  setActiveKey,
  sendOrder,
  resetOrders,
  addItem,
  editItem,
  delItem,
  splitOrder,
  modalOrderOpen,
  setModalOrderOpen,
  pagination,
  editOrder
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [orderItemNotes, setOrderItemNotes] = useState("");
  const [orderItemQty, setOrderItemQty] = useState(0);
  const [modalOrderDetailOpen, setModalOrderDetailOpen] = useState(false);
  const [showSplitBill, setShowSplitBill] = useState(false);
  const [mountQty, setMountQty] = useState(true);
  const [splitBillItems, setSplitBillItems] = useState<MerchantSaleItem[]>([]);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [discount, setDiscount] = useState<Discount | null>(null);
  const [selectedDiscount, setSelectedDiscount] = useState<Discount | null>(
    null
  );
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<MerchantSaleItem>();
  const [modalItemOpen, setModalItemOpen] = useState(false);
  const clearMountQty = () => {
    // console.log("clear")
    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 50);
  };

  //   useEffect(() => {
  //     console.log(isModalOrderOpen)
  //     setModalOrderOpen(isModalOrderOpen);
  //   }, [isModalOrderOpen]);

  useEffect(() => {
    if (selectedOrderItem) {
      let disc = checkDiscounts(
        discounts,
        selectedOrderItem,
        selectedOrder?.customer
      );

      if (disc.length > 0) {
        setDiscount(disc[0]);
      } else {
        setSelectedDiscount(null);
        setDiscount(null);
      }
    }
  }, [selectedOrderItem]);
  return (
    <>
      <div className="w-full  flex flex-row">
        <div className="flex flex-col w-full ">
          <div
            className={`grid ${
              salesItems.length > 0 ? "grid-cols-3" : "grid-cols-4"
            } gap-2 py-4  overflow-y-auto  `}
          >
            {orders.map((e) => (
              <div className="bg-white " key={e.uuid}>
                <div
                  className="m-2 h-[120px]  bg-white border border-gray-300 rounded-lg shadow-sm cursor-pointer flex flex-row"
                  onClick={() => {
                    setModalOrderDetailOpen(true);
                    setSelectedOrder(e);
                  }}
                >
                  <img
                    src={e.merchant_table_occupancy.room?.picture}
                    className=" rounded-lg mr-2 h-full"
                    alt=""
                  />

                  <div className="p-2 w-full">
                    <p className="text-sm">
                      {e.merchant_table_occupancy.room?.name}
                    </p>
                    {e.table_data && (
                      <div className="flex flex-row justify-between">
                        <div className="text-sm">Meja {e.table_data?.text}</div>
                        <div>{e.customer_name}</div>
                      </div>
                    )}
                    <h3 className="text-sm font-semibold gap-2 flex flex-row items-center">
                      {e.merchant_table_occupancy?.table_alias}
                      <span className="font-normal text-xs italic">
                        (
                        {moment(
                          e.merchant_table_occupancy.start_time
                            ? e.merchant_table_occupancy?.start_time
                            : e.created_at
                        ).fromNow()}
                        )
                      </span>
                    </h3>
                    <div className="flex flex-col">
                      <small>{e.data.length} items</small>
                      <div
                        className={`text-white text-center w-24 text-xs rounded-lg ${
                          {
                            PAID: "bg-green-500",
                            Active: "bg-blue-500",
                            PROCESSING: "bg-violet-500",
                            Cancel: "bg-red-500",
                          }[e.status] || ""
                        }`}
                      >
                        {e.status}
                      </div>
                    </div>
                    <div className="flex flex-row justify-end items-end w-full font-semibold text-orange-600 text-lg">
                      {money(countGrandTotal(e.data, merchant, tax))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {pagination}
        </div>
        {salesItems.length > 0 && (
          <div className="w-1/4 border-l flex flex-col">
            <div className="flex-1">
              <div className="px-2 border-b py-2 flex flex-row justify-between">
                <h3 className="text-xl font-semibold ">
                  {tableOccupied?.table_alias}
                </h3>
                <div className="flex flex-row gap-2">
                  <Button
                    size="xs"
                    onClick={() => setModalOrderOpen(true)}
                    appearance="primary"
                    color="red"
                  >
                    <PiPlusBold className="" />
                  </Button>
                </div>
              </div>
              <ul className="overflow-y-auto">
                {salesItems.map((e) => {
                  let discItems = checkDiscounts(
                    discounts,
                    e,
                    tableOccupied?.customer
                  );
                  // console.log("discItems", discItems)
                  return (
                    <li
                      key={e.uniqueId}
                      className=" flex justify-between  gap-2 hover:bg-gray-100 transition-colors  last:border-b-0  border-b px-2 py-2 cursor-pointer"
                    >
                      <div className="flex ">
                        <div className="flex flex-col">
                          <p className="font-semibold md:text-base mb-2">
                            {e.product.name}
                          </p>
                          {e.varian != null ? (
                            <p className=" -mt-1 font-xs">
                              {parseVarian(e.varian!.data, true)}
                            </p>
                          ) : null}

                          <small className="">
                            {money(e.qty)} x{" "}
                            {money(e.price - (e.discount_amount ?? 0))}
                          </small>
                          {discItems.length == 1 && e.discount == null && (
                            <small
                              onClick={() => {
                                console.log(discItems);
                                editItem(e, e.qty, true, discItems[0]);
                                clearMountQty();
                              }}
                              className=" text-orange-600"
                            >
                              Terapkan diskon{" "}
                              {discItems[0].type == 1
                                ? `${discItems[0].percent}%`
                                : discItems[0].amount}
                            </small>
                          )}
                          {e.notes && (
                            <div className="flex flex-col text-xs mt-4">
                              <p className="font-semibold">Catatan</p>
                              <p>{e.notes}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex text-xl  md:text-base font-semibold text-orange-600 py-2 flex-col items-end h-full">
                        <span>
                          {money((e.price - (e.discount_amount ?? 0)) * e.qty)}
                        </span>
                        <BiTrash
                          className="text-red-400 hover:text-red-600"
                          onClick={() => delItem(e)}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full px-2  pb-4 border-t space-y-2">
              <div className="flex justify-between items-end">
                <p className=" text-gray-600">Total</p>
                <h1 className=" text-2xl font-semibold text-red-600">
                  {money(countGrandTotal(salesItems, merchant, tax))}
                </h1>
              </div>
              <Button
                className="w-full"
                appearance="primary"
                color="yellow"
                loading={isLoading}
                onClick={sendOrder}
              >
                Kirim Pesanan
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        size={"xl"}
        open={modalOrderOpen}
        onClose={() => setModalOrderOpen(false)}
        className="modal-order"
      >
        <Modal.Body style={{ margin: 0, padding: 0 }}>
          <div className="grid grid-cols-4">
            <div className="col-span-3">
              <OrderProduct
                merchant={merchant}
                addItem={(item, varian, discount) => {
                  setOrderItemNotes("");
                  setOrderItemQty(1);
                  clearMountQty();
                  setSelectedProduct(item);
                }}
              />
            </div>
            <div className="col-span-1">
              {selectedProduct && (
                <>
                  <div className="flex flex-col h-full cursor-pointer">
                    <div className=" h-3/4 relative">
                      {selectedProduct?.default_picture ? (
                        <img
                          src={selectedProduct?.default_picture}
                          alt=""
                          className="rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600"
                        />
                      ) : (
                        <img
                          src="/images/logo-niaga.png"
                          alt=""
                          className="rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600"
                        />
                      )}
                      <div className=" absolute top-2 right-2 py-1 px-2 rounded  bg-opacity-70 bg-pink-500 text-xs text-white">
                        {selectedProduct?.product_category}
                      </div>
                    </div>

                    <div className="h-1/4  py-2 flex flex-col">
                      <p className="text-lg font-semibold">
                        {" "}
                        {selectedProduct?.name}
                      </p>
                      <div className="flex  font-semibold text-orange-600">
                        {money(selectedProduct?.prices[0].amount)}
                      </div>
                    </div>
                    <div className="flex flex-col mb-2">
                      <div className="flex items-center">
                        <MinusCircleIcon
                          onClick={() => {
                            clearMountQty();
                            setOrderItemQty((val) => {
                              if (val - 1 <= 0) {
                                return 0;
                              }
                              let disc = checkDiscountByProductId(
                                discounts,
                                selectedProduct!.uuid!,
                                val - 1,
                                tableOccupied?.customer
                              );
                              if (disc.length > 0) {
                                setDiscount(disc[0]);
                              } else {
                                setSelectedDiscount(null);
                              }
                              return val - 1;
                            });
                          }}
                          className="w-6 text-blue-500"
                        />
                        {mountQty ? (
                          <CurrencyInput
                            className="bg-white mx-2 w-full text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            groupSeparator="."
                            decimalSeparator=","
                            placeholder="Jumlah"
                            defaultValue={orderItemQty}
                            onSubmit={() => {
                              setMountQty(false);
                              setTimeout(() => {
                                setMountQty(true);
                              }, 10);
                            }}
                            onValueChange={(value, _, values) => {
                              setOrderItemQty(values?.float ?? 0);
                              let disc = checkDiscountByProductId(
                                discounts,
                                selectedProduct!.uuid!,
                                values?.float ?? 0,
                                tableOccupied?.customer
                              );
                              if (disc.length > 0) {
                                setDiscount(disc[0]);
                              } else {
                                setSelectedDiscount(null);
                              }
                            }}
                          />
                        ) : (
                          <div className="bg-white mx-2 w-full text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                            {money(orderItemQty)}
                          </div>
                        )}

                        <PlusCircleIcon
                          onClick={() => {
                            clearMountQty();
                            setOrderItemQty((val) => {
                              let disc = checkDiscountByProductId(
                                discounts,
                                selectedProduct!.uuid!,
                                val + 1,
                                tableOccupied?.customer
                              );
                              if (disc.length > 0) {
                                setDiscount(disc[0]);
                              } else {
                                setSelectedDiscount(null);
                              }
                              return val + 1;
                            });
                          }}
                          className="w-6 text-green-500"
                        />
                      </div>
                    </div>
                    {discount && selectedDiscount == null && (
                      <small
                        onClick={() => {
                          setSelectedDiscount(discount);
                          setDiscount(null);
                        }}
                        className=" text-orange-600"
                      >
                        Terapkan diskon{" "}
                        {discount.type == 1
                          ? `${discount.percent}%`
                          : discount.amount}
                      </small>
                    )}
                    <div className="flex flex-col mb-2">
                      <p className="text-sm font-semibold">Catatan</p>
                      <Input
                        as={"textarea"}
                        rows={3}
                        placeholder="Keterangan"
                        value={orderItemNotes}
                        onChange={(val) => setOrderItemNotes(val)}
                      />
                    </div>
                    <div className="flex flex-row mb-2">
                      {selectedProduct.notes_template.map((e, i) => (
                        <Tag
                          key={i}
                          onClick={() =>
                            setOrderItemNotes((val) => `${val} ${e}`)
                          }
                        >
                          {e}
                        </Tag>
                      ))}
                    </div>
                    <div className="h-1/4  py-2 flex flex-row space-x-2 items-end">
                      <p className="text-sm font-semibold mb-1">Total</p>
                      <small></small>
                      <div className="flex text-2xl  font-semibold text-orange-600">
                        {money(
                          countItemByQty(
                            orderItemQty,
                            selectedProduct?.prices[0].amount,
                            selectedDiscount,
                            merchant,
                            tax
                          ).priceAfterTax
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {selectedProduct && (
                <Button
                  className="w-full mt-4"
                  appearance="primary"
                  onClick={() => {
                    setSelectedProduct((val) => {
                      addItem(val!, null, selectedDiscount, orderItemQty);
                      setOrderItemQty(1);
                      setOrderItemNotes("");
                      return undefined;
                    });
                  }}
                >
                  Simpan
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="subtle" onClick={() => setModalOrderOpen(false)}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className=""
        size={"lg"}
        open={modalOrderDetailOpen}
        onClose={() => setModalOrderDetailOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Detail Pesanan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-2 gap-2 mb-4">
            <Panel bordered header="Info">
              <InlineForm style={{ marginBottom: 10 }} title={"Kode Pesanan"}>
                {selectedOrder?.code}
              </InlineForm>
              {selectedOrder?.merchant_table_occupancy?.room && (
                <InlineForm style={{ marginBottom: 10 }} title={"Ruangan"}>
                  {selectedOrder?.merchant_table_occupancy?.room?.name}
                </InlineForm>
              )}
              <InlineForm style={{ marginBottom: 10 }} title={"Meja"}>
                {selectedOrder?.merchant_table_occupancy?.table_alias}
                {selectedOrder?.table_data?.text}
              </InlineForm>
              <InlineForm style={{ marginBottom: 10 }} title={"Tgl / Waktu"}>
                <Moment format="DD MMM YYYY, HH:mm">
                  {selectedOrder?.merchant_table_occupancy?.start_time
                    ? selectedOrder?.merchant_table_occupancy?.start_time
                    : selectedOrder?.created_at}
                </Moment>
              </InlineForm>
              <InlineForm style={{ marginBottom: 10 }} title={"Konsumen"}>
                {selectedOrder?.customer_name}
              </InlineForm>
              <InlineForm style={{ marginBottom: 10 }} title={"Telp"}>
                {selectedOrder?.customer_phone}
              </InlineForm>

              <InlineForm style={{ marginBottom: 10 }} title={"Status"}>
                {selectedOrder?.status && (
                  <div
                    className={`text-white text-center w-24 text-xs rounded-lg ${
                      {
                        PAID: "bg-green-500",
                        Active: "bg-blue-500",
                        PROCESSING: "bg-violet-500",
                        Cancel: "bg-red-500",
                      }[selectedOrder?.status!] || ""
                    }`}
                  >
                    {selectedOrder?.status!}
                  </div>
                )}
              </InlineForm>

              {selectedOrder?.merchant_table_occupancy?.notes && (
                <InlineForm style={{ marginBottom: 10 }} title={"Catatan"}>
                  {selectedOrder?.merchant_table_occupancy?.notes}
                </InlineForm>
              )}
            </Panel>
            <Panel bordered header="Item Pesanan">
              {selectedOrder?.data.map((e, i) => {
                return (
                  <div key={i} className="mb-4">
                    <div
                      className={`flex items-center justify-between ${
                        e.status == "Cancel" && "bg-red-50"
                      } p-2`}
                    >
                      <div className="flex flex-row">
                        <Avatar
                          name={e.product?.name}
                          url={e.product?.default_picture}
                        />
                        <div>
                          <p className="font-semibold">{e.product?.name}</p>
                          <small>
                            {money(e.qty)} {e.unit_name} x {money(e.price)}
                          </small>
                          {e.status == "Done" && (
                            <div className=" w-24 bg-green-600 hover:bg-green-800 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg">
                              Selesai
                            </div>
                          )}
                          {e.status == "Cancel" && (
                            <>
                              <div className=" w-24 bg-gray-400 hover:bg-gray-800 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg">
                                Batal
                              </div>
                              <small className="italic text-xs">
                                "{e.remarks}"
                              </small>
                            </>
                          )}
                          {!e.status && (selectedOrder?.status == "Active" || selectedOrder?.status == "PROCESSING") && (
                            <div className="flex flex-row gap-2">
                              <div
                                className=" w-24 bg-orange-600 hover:bg-orange-800 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg"
                                onClick={() => {
                                  setModalItemOpen(true);
                                  setModalOrderDetailOpen(false);
                                  setSelectedOrderItem(e);
                                }}
                              >
                                Edit Pesanan
                              </div>
                              <div
                                className=" w-24 bg-red-600 hover:bg-red-800 text-[7pt] text-white px-2 text-center cursor-pointer py-0.5 rounded-lg"
                                onClick={() => {
                                  setModalOrderDetailOpen(false);
                                  Swal.fire({
                                    title: "Batalkan Item " + e.product?.name,
                                    input: "text",
                                    inputPlaceholder:
                                      "Masukkan Alasan Pembatalan...",
                                    showCancelButton: true,
                                    confirmButtonText: "Batalkan",
                                    cancelButtonText: "Batal",
                                    inputValidator: (value) => {
                                      if (!value) {
                                        return "Masukkan alasan";
                                      }
                                    },
                                  })
                                    .then(async (result) => {
                                      if (result.isConfirmed) {
                                        try {
                                          // add cancelation process
                                          await cancelPosOrderItem(
                                            selectedOrder!.uuid,
                                            e.uniqueId,
                                            result.value
                                          );
                                          const res = await getPosOrderDetail(
                                            selectedOrder!.uuid
                                          );
                                          const data = await res.json();
                                          setSelectedOrder(data.data);
                                          resetOrders();
                                        } catch (error) {
                                          errorToast(`${error}`);
                                        }
                                      }
                                    })
                                    .finally(() =>
                                      setModalOrderDetailOpen(true)
                                    );
                                }}
                              >
                                Batalkan Pesanan
                              </div>
                            </div>
                          )}

                          {e.discount && (
                            <Tag color="violet" size="sm">
                              {e.discount.type == 1
                                ? `${e.discount?.percent} %`
                                : `${money(e.discount?.amount)}`}
                            </Tag>
                          )}

                          <p className="text-sm">{e.notes}</p>
                          {e.status != "Cancel" && e.remarks && (
                            <small className="italic text-xs">
                              "{e.remarks}"
                            </small>
                          )}
                        </div>
                      </div>
                      <p
                        className={`font-semibold text-orange-600 ${
                          e.status == "Cancel" && "line-through"
                        }`}
                      >
                        {money(
                          countItem(e, e.discount, merchant, tax)
                            .price_after_tax
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <p className=" font-semibold text-sm">Subtotal</p>
                  <h3 className="font-[500] text-lg">
                    {money(countSubTotal(selectedOrder?.data ?? []))}
                  </h3>
                </div>
                {countDiscount(selectedOrder?.data ?? []) > 0 && (
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold text-sm">Total Diskon</p>
                    <h3 className="font-[500] text-lg">
                      {money(countDiscount(selectedOrder?.data ?? []))}
                    </h3>
                  </div>
                )}
                {merchant != null && tax && (
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold text-sm">
                      {tax.name} ({tax.amount}%)
                    </p>
                    <h3 className="font-[500] text-normal">
                      {money(
                        countTax(selectedOrder?.data ?? [], merchant, tax)
                      )}
                    </h3>
                  </div>
                )}
                <div className="flex justify-between items-center">
                  <p className=" font-semibold text-sm">Total</p>
                  <h3 className="font-[500] text-lg">
                    {money(
                      countGrandTotal(selectedOrder?.data ?? [], merchant, tax)
                    )}
                  </h3>
                </div>
              </div>
              {(selectedOrder?.status == "Active" || selectedOrder?.status == "PROCESSING" ) && (
                <div className="mt-8 grid grid-cols-3  space-x-2 ">
                  <Button
                    appearance="primary"
                    color="orange"
                    onClick={() => {
                      setShowSplitBill((val) => {
                        if (!val) {
                          setSplitBillItems([
                            ...selectedOrder!.data.map((e) => {
                              return {
                                ...e,
                                qty: 0,
                              };
                            }),
                          ]);
                        } else {
                          setSplitBillItems([]);
                        }
                        return !val;
                      });
                    }}
                  >
                    Split Bill
                  </Button>
                  <Button
                    appearance="primary"
                    color="green"
                    onClick={() => {
                      setModalOrderDetailOpen(false);
                      setModalOrderOpen(true);
                      setTableOccupied(selectedOrder?.merchant_table_occupancy);
                    }}
                  >
                    Tambah Item
                  </Button>

                  <Button
                    appearance="primary"
                    color="blue"
                    onClick={() => {
                      setSelectedOrder({
                        ...selectedOrder!,
                      });
                      setActiveKey("payment");
                      setModalOrderDetailOpen(false);
                    }}
                  >
                    Pembayaran
                  </Button>
                </div>
              )}
            </Panel>
          </div>
          {showSplitBill && (
            <div className="grid grid-cols-2 gap-2">
              <Panel bordered>
                {(selectedOrder?.data ?? []).map((e, i) => (
                  <div key={i}>
                    <div className="flex justify-between">
                      <div className="flex flex-col w-full">
                        <p className="font-semibold">{e.product?.name}</p>
                        <p className="text-sm">
                          {money(e.price)} x{" "}
                          {money(
                            e.qty -
                              splitBillItems.find(
                                (s) => s.uniqueId == e.uniqueId
                              )!.qty
                          )}
                        </p>
                      </div>
                      <h3 className=" text-orange-600 font-semibold">
                        {money(
                          e.price *
                            (e.qty -
                              splitBillItems.find(
                                (s) => s.uniqueId == e.uniqueId
                              )!.qty)
                        )}
                      </h3>
                    </div>
                  </div>
                ))}
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold text-sm">Subtotal</p>
                    <h3 className="font-[500] text-lg">
                      {money(
                        countSubTotal([
                          ...(selectedOrder?.data ?? []).map((e) => {
                            return {
                              ...e,
                              qty:
                                e.qty -
                                splitBillItems.find(
                                  (s) => s.uniqueId == e.uniqueId
                                )!.qty,
                            };
                          }),
                        ])
                      )}
                    </h3>
                  </div>
                  {countDiscount([
                    ...(selectedOrder?.data ?? []).map((e) => {
                      return {
                        ...e,
                        qty:
                          e.qty -
                          splitBillItems.find((s) => s.uniqueId == e.uniqueId)!
                            .qty,
                      };
                    }),
                  ]) > 0 && (
                    <div className="flex justify-between items-center">
                      <p className=" font-semibold text-sm">Total Diskon</p>
                      <h3 className="font-[500] text-lg">
                        {money(
                          countDiscount([
                            ...(selectedOrder?.data ?? []).map((e) => {
                              return {
                                ...e,
                                qty:
                                  e.qty -
                                  splitBillItems.find(
                                    (s) => s.uniqueId == e.uniqueId
                                  )!.qty,
                              };
                            }),
                          ])
                        )}
                      </h3>
                    </div>
                  )}
                  {merchant != null && tax && (
                    <div className="flex justify-between items-center">
                      <p className=" font-semibold text-sm">
                        {tax.name} ({tax.amount}%)
                      </p>
                      <h3 className="font-[500] text-normal">
                        {money(
                          countTax(
                            [
                              ...(selectedOrder?.data ?? []).map((e) => {
                                return {
                                  ...e,
                                  qty:
                                    e.qty -
                                    splitBillItems.find(
                                      (s) => s.uniqueId == e.uniqueId
                                    )!.qty,
                                };
                              }),
                            ],
                            merchant,
                            tax
                          )
                        )}
                      </h3>
                    </div>
                  )}
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold text-sm">Total</p>
                    <h3 className="font-[500] text-lg">
                      {money(
                        countGrandTotal(
                          [
                            ...(selectedOrder?.data ?? []).map((e) => {
                              return {
                                ...e,
                                qty:
                                  e.qty -
                                  splitBillItems.find(
                                    (s) => s.uniqueId == e.uniqueId
                                  )!.qty,
                              };
                            }),
                          ],
                          merchant,
                          tax
                        )
                      )}
                    </h3>
                  </div>
                </div>
              </Panel>
              <Panel bordered>
                {splitBillItems.map((e, i) => (
                  <div key={i}>
                    <div className="flex justify-between">
                      <div className="flex flex-col w-full">
                        <p className="font-semibold">{e.product?.name}</p>
                        <p className="text-sm">{money(e.price)}</p>
                        <h3 className=" text-orange-600 font-semibold">
                          {money(e.price * e.qty)}
                        </h3>
                      </div>
                      <div className="flex items-center w-1/2">
                        <MinusCircleIcon
                          onClick={() => {
                            clearMountQty();
                            setSplitBillItems([
                              ...splitBillItems.map((i) => {
                                if (i.uniqueId === e.uniqueId) {
                                  if (i.qty > 1) {
                                    return { ...i, qty: i.qty - 1 };
                                  }
                                }
                                return i;
                              }),
                            ]);
                          }}
                          className="w-6 text-blue-500"
                        />
                        {mountQty ? (
                          <CurrencyInput
                            className="bg-white mx-2 w-full text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            groupSeparator="."
                            decimalSeparator=","
                            placeholder="Jumlah"
                            defaultValue={e.qty}
                            onBlur={() => {
                              clearMountQty();
                            }}
                            onValueChange={(value, _, values) => {
                              setSplitBillItems([
                                ...splitBillItems.map((i) => {
                                  if (i.uniqueId === e.uniqueId) {
                                    if (
                                      (values?.float ?? 1) <=
                                      selectedOrder!.data!.find(
                                        (d) => d.uniqueId == i.uniqueId
                                      )!.qty
                                    ) {
                                      return { ...i, qty: values?.float ?? 1 };
                                    } else {
                                      return {
                                        ...i,
                                        qty: selectedOrder!.data!.find(
                                          (d) => d.uniqueId == i.uniqueId
                                        )!.qty,
                                      };
                                    }
                                  }
                                  return i;
                                }),
                              ]);
                            }}
                          />
                        ) : (
                          <div className="bg-white mx-2 w-full text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                            {money(e.qty)}
                          </div>
                        )}

                        <PlusCircleIcon
                          onClick={() => {
                            clearMountQty();
                            setSplitBillItems([
                              ...splitBillItems.map((i) => {
                                if (i.uniqueId === e.uniqueId) {
                                  if (
                                    i.qty + 1 <=
                                    selectedOrder!.data!.find(
                                      (d) => d.uniqueId == i.uniqueId
                                    )!.qty
                                  ) {
                                    return { ...i, qty: i.qty + 1 };
                                  }
                                }
                                return i;
                              }),
                            ]);
                          }}
                          className="w-6 text-green-500"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex-1">
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold text-sm">Subtotal</p>
                    <h3 className="font-[500] text-lg">
                      {money(countSubTotal(splitBillItems))}
                    </h3>
                  </div>
                  {countDiscount(splitBillItems) > 0 && (
                    <div className="flex justify-between items-center">
                      <p className=" font-semibold text-sm">Total Diskon</p>
                      <h3 className="font-[500] text-lg">
                        {money(countDiscount(splitBillItems))}
                      </h3>
                    </div>
                  )}
                  {merchant != null && tax && (
                    <div className="flex justify-between items-center">
                      <p className=" font-semibold text-sm">
                        {tax.name} ({tax.amount}%)
                      </p>
                      <h3 className="font-[500] text-normal">
                        {money(countTax(splitBillItems, merchant, tax))}
                      </h3>
                    </div>
                  )}
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold text-sm">Total</p>
                    <h3 className="font-[500] text-lg">
                      {money(countGrandTotal(splitBillItems, merchant, tax))}
                    </h3>
                  </div>
                </div>
                <div className="mt-8 flex flex-row justify-between space-x-2">
                  <div className="w-full">
                    <Input
                      value={newCustomerName}
                      onChange={(e) => setNewCustomerName(e)}
                      type="text"
                      placeholder="Nama Pelanggan"
                      className="input-form"
                    />
                  </div>
                  <Button
                    className="w-32"
                    appearance="primary"
                    color="blue"
                    onClick={async () => {
                      if (!newCustomerName) {
                        errorToast("Nama Pelanggan tidak boleh kosong");
                        return;
                      }

                      let ok = await splitOrder(
                        newCustomerName,
                        splitBillItems
                      );
                      if (ok) {
                        setModalOrderDetailOpen(false);
                        successToast("Order berhasil dibuat");
                        setNewCustomerName("");
                        setSplitBillItems([]);
                      }
                    }}
                    loading={isLoading}
                  >
                    Kirim
                  </Button>
                </div>
              </Panel>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedOrder?.status == "Active" && (
            <InlineForm style={{ marginBottom: 10 }} title={""}>
              <Button
                className=""
                appearance="primary"
                color="violet"
                onClick={() => {
                  selectedOrder.status = "PROCESSING"
                  editOrder(selectedOrder)
                }}
              >
                Process
              </Button>
            </InlineForm>
          )}
        </Modal.Footer>
      </Modal>
      <Modal open={modalItemOpen} onClose={() => setModalItemOpen(false)}>
        <Modal.Header>
          <Modal.Title>Edit {selectedOrderItem?.product?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrderItem && (
            <div className="flex flex-col">
              <div className="flex justify-between">
                <div className="flex flex-col w-full">
                  <p className="font-semibold">
                    {selectedOrderItem?.product?.name}
                  </p>
                  <p className="text-sm">{money(selectedOrderItem?.price)}</p>
                  <h3 className=" text-orange-600 font-semibold">
                    {money(
                      countItem(
                        selectedOrderItem!,
                        selectedDiscount,
                        merchant,
                        tax
                      ).price_after_tax
                    )}
                  </h3>
                </div>
                <div className="flex items-center w-1/2">
                  <MinusCircleIcon
                    onClick={() => {
                      clearMountQty();
                      setSelectedOrderItem((val) => {
                        if (val!.qty - 1 <= 0) {
                          return val;
                        }

                        return {
                          ...val!,
                          qty: val!.qty - 1,
                        };
                      });
                    }}
                    className="w-6 text-blue-500"
                  />
                  {mountQty ? (
                    <CurrencyInput
                      className="bg-white mx-2 w-full text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      groupSeparator="."
                      decimalSeparator=","
                      placeholder="Jumlah"
                      defaultValue={selectedOrderItem!?.qty ?? 0}
                      onBlur={() => {
                        clearMountQty();
                      }}
                      onValueChange={(value, _, values) => {
                        setSelectedOrderItem((val) => {
                          return {
                            ...val!,
                            qty: values?.float ?? val?.qty ?? 0,
                          };
                        });
                      }}
                    />
                  ) : (
                    <div className="bg-white mx-2 w-full text-right appearance-none border border-gray-200 rounded-xl  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                      {money(selectedOrderItem!?.qty ?? 0)}
                    </div>
                  )}

                  <PlusCircleIcon
                    onClick={() => {
                      clearMountQty();

                      setSelectedOrderItem((val) => {
                        if (
                          val!.qty + 1 >
                          ((selectedOrder?.data ?? []).find(
                            (e) => e.uniqueId == selectedOrderItem?.uniqueId
                          )?.qty ?? 0)
                        ) {
                          return val;
                        }

                        return {
                          ...val!,
                          qty: val!.qty + 1,
                        };
                      });
                    }}
                    className="w-6 text-green-500"
                  />
                </div>
              </div>
              {discount && selectedDiscount == null && (
                <small
                  onClick={() => {
                    setSelectedDiscount(discount);
                    setDiscount(null);
                  }}
                  className=" text-orange-600"
                >
                  Terapkan diskon{" "}
                  {discount.type == 1
                    ? `${discount.percent}%`
                    : discount.amount}
                </small>
              )}
              <div className="my-2">
                <label
                  htmlFor="remarks"
                  className="block text-sm font-medium text-gray-700"
                >
                  Catatan
                </label>
                <Input
                  id="remarks"
                  value={selectedOrderItem?.remarks ?? ""}
                  onChange={(e) => {
                    setSelectedOrderItem((val) => {
                      return {
                        ...val!,
                        remarks: e,
                      };
                    });
                  }}
                  as={"textarea"}
                  rows={3}
                  placeholder="Catatan"
                  className="mt-1 block w-full"
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="subtle"
            onClick={() => {
              setModalItemOpen(false);
              setModalOrderDetailOpen(true);
            }}
          >
            Tutup
          </Button>
          <Button
            appearance="primary"
            onClick={async () => {
              try {
                await editPosOrderItem(
                  selectedOrder?.uuid!,
                  selectedOrderItem?.uniqueId!,
                  selectedOrderItem
                );
                let resp = await getPosOrderDetail(selectedOrder?.uuid!);
                let respJson = await resp.json();
                setSelectedOrder(respJson.data);
                setModalItemOpen(false);
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setModalOrderDetailOpen(true);
              }
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RestoOrder;
