import { useContext, useEffect, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { getQrMenuRoom, getQrMenuRoomProducts } from "../repositories/qr_menu";
import { Merchant, MerchantRoom } from "../model/merchant";
import {
  PriceLevelData,
  Product,
  ProductCategory,
  VarianStock,
} from "../model/product";
import { money } from "../utils/number";
import { TbShoppingCart } from "react-icons/tb";
import { Button, Drawer } from "rsuite";
import { MerchantSaleItem } from "../model/pos";
import { Discount } from "../model/discount";
import {
  asyncLocalStorage,
  randomStr,
  reverseArr,
  setNullString,
} from "../utils/helper";
import { countItem } from "../utils/pos_helper";
import { Tax } from "../model/tax";
import OrderItems from "../components/OrderItems";
import { Customer } from "../model/customer";
import { successToast } from "../utils/helper-ui";
import Swal from "sweetalert2";
import { addPosOrder, addPosQROrder } from "../repositories/pos";
import { Shape } from "../model/shape";
import { UserIcon } from "@heroicons/react/24/outline";
import { LuCircle } from "react-icons/lu";
import { PiUserCircle, PiUserCircleBold } from "react-icons/pi";
import { LoadingContext } from "../objects/loading_context";

interface QrMenuPageProps {}

const QrMenuPage: FC<QrMenuPageProps> = ({}) => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const { roomId, tableId } = useParams();
  const [mounted, setMounted] = useState(false);
  const [merchant, setMerchant] = useState<Merchant>();
  const [room, setRoom] = useState<MerchantRoom>();
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [table, setTable] = useState<Shape>();
  const [salesItems, setSalesItems] = useState<MerchantSaleItem[]>([]);
  const [tax, setTax] = useState<Tax | null>(null);
  const [openCart, setOpenCart] = useState(false);
  const [mountQty, setMountQty] = useState(true);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [activeDiscounts, setActiveDiscounts] = useState<Discount[]>([]);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getQrMenuRoom(roomId!)
      .then((e) => e.json())
      .then((res) => {
        setMerchant(res.data.merchant);
        setRoom(res.data.room);
        setCategories(res.data.categories);
        setTax(res.data.tax);
      });

    asyncLocalStorage.getItem("customer-name").then(setCustomerName);
    asyncLocalStorage.getItem("customer-phone").then(setCustomerPhone);
  }, [mounted, roomId]);

  useEffect(() => {
    if (search) {
      getQrMenuRoomProducts(roomId!, undefined, search)
        .then((e) => e.json())
        .then((res) => {
          setProducts(res.data.data);
        });
    }
  }, [search]);

  useEffect(() => {
    if (room) {
      setTable(room.tables.find((e) => e.id == tableId));
    }
  }, [room, tableId]);

  const addItem = (
    item: Product,
    varian?: VarianStock | null,
    discount?: Discount | null
  ) => {
    let itemExist = salesItems.find((e) => e.product.uuid == item.uuid);
    if (item.varians.length > 0 && varian != null) {
      itemExist = salesItems.find(
        (e) => e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid
      );
    }
    let qty = 1;

    if (!itemExist) {
      let saleItem: MerchantSaleItem = {
        uniqueId: randomStr(3),
        product: item,
        qty: qty,
        discount: discount,
        discount_id: discount?.uuid,
        isService: false,
        price: item.prices[0].amount,
        varian: varian,
        tax_id: tax?.uuid,
        tax: tax,
      };
      salesItems.push(countItem(saleItem, discount, merchant, tax));

      setSalesItems([...salesItems]);
    } else {
      let newItems = salesItems.map((e) => {
        if (item.varians.length > 0 && varian != null) {
          if (e.product.uuid == item.uuid && e.varian?.uuid == varian.uuid) {
            e.qty++;
            e = countItem(e, discount, merchant, tax);
          }
        } else {
          if (e.product.uuid == item.uuid) {
            e.qty++;
            e = countItem(e, discount, merchant, tax);
          }
        }

        return e;
      });

      setSalesItems([...newItems]);
    }

    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 10);
    successToast(`${item.name} ditambahkan`);
  };

  const checkDiscounts = (item: MerchantSaleItem): Discount[] => {
    return discounts.filter((e) => {
      if (!item.product) return false;
      let isAllProduct =
        e.min_qty <= item.qty &&
        e.max_qty >= item.qty &&
        e.products.map((e) => e.uuid).includes(item.product?.uuid!);
      let isAllCustomer = e.is_all_customer
        ? true
        : e.min_qty <= item.qty &&
          e.max_qty >= item.qty &&
          customer &&
          e.customers.map((e) => e.uuid).includes(customer.uuid);

      return isAllProduct && isAllCustomer;
    });
  };
  const editItem = (
    item: MerchantSaleItem,
    qty: number,
    refresh?: boolean,
    discount?: Discount | null
  ) => {
    
    let newItems = salesItems.map((e) => {
      if (item.uniqueId == e.uniqueId) {
        if (item.product.prices[0].price_level_data.length > 0) {
          let levels: PriceLevelData[] = reverseArr(
            item.product.prices[0].price_level_data
          );
          for (const key in levels) {
            if (Object.prototype.hasOwnProperty.call(levels, key)) {
              const element = levels[key];
              if (qty >= element.min) {
                e.price = element.amount;
                // console.log(element.min)
                break;
              }
            }
          }
        }
        e.qty = qty;
        e = countItem(e, discount);
        e.notes = item.notes
      }
      return e;
    });
    setSalesItems([...newItems]);
    if (refresh) {
      setMountQty(false);
      setTimeout(() => {
        setMountQty(true);
      }, 10);
    }
  };

  const delItem = (item: MerchantSaleItem) => {
    let newItems = salesItems.filter((e) => e.uniqueId != item.uniqueId);
    setSalesItems([...newItems]);
    setMountQty(false);
    setTimeout(() => {
      setMountQty(true);
    }, 10);
  };

  const editCustomer = async () => {
    setOpenCart(false)
    const { value: name } = await Swal.fire({
      title: "Isikan Nama Pemesan",
      input: "text",
      inputLabel: "Nama Lengkap / Sebutan",
      inputValue: customerName,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Simpan!",
      cancelButtonText: "Batal",
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });

    if (name) {
      setCustomerName(name);
      asyncLocalStorage.setItem("customer-name", name);
    }
    const { value: phone } = await Swal.fire({
      title: "Isikan No HP Pemesan",
      input: "text",
      inputLabel: "No HP (opsional)",
      inputValue: customerPhone,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Simpan!",
      cancelButtonText: "Batal",
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });

    if (phone) {
      setCustomerPhone(phone);
      asyncLocalStorage.setItem("customer-phone", phone);
    }
  };

  return (
    <div className="max-w-[768px] mx-auto bg-white h-screen flex flex-col p-4 overflow-y-auto relative">
      <div className="header flex flex-col justify-center items-center">
        {merchant?.logo && <img src={merchant?.logo} alt="" className="w-16" />}
        <h1 className="text-center text-4xl font-bold">{merchant?.name}</h1>
        <p className="text-center text-sm">{merchant?.address}</p>
        <small className="text-center text-xs">{merchant?.phone}</small>
        <div className="search w-full">
          <div className="relative mt-4 w-full">
            <input
              type="text"
              placeholder="Search..."
              className="border rounded-xl p-2 pl-10 w-full"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <svg
              className="absolute left-3 top-3 w-5 h-5 text-gray-500"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M10 2a8 8 0 105.293 14.707l4.242 4.242 1.414-1.414-4.242-4.242A8 8 0 0010 2zm0 2a6 6 0 110 12 6 6 0 010-12z" />
            </svg>
          </div>
        </div>
        {products.length == 0 && (
          <div className="mt-8 flex flex-col items-center gap-y-4">
            {(categories ?? []).map((e) => (
              <div
                key={e.uuid}
                className="bg-gray-100 p-2 rounded-lg w-fit px-8"
                onClick={() => {
                  getQrMenuRoomProducts(roomId!, e.uuid)
                    .then((e) => e.json())
                    .then((res) => {
                      setProducts(res.data.data);
                    });
                }}
                style={{ backgroundColor: merchant?.qr_menu_main_color ?? "purple", color: "white" }}
              >
                <h2 className="text-lg font-bold">{e.name}</h2>
              </div>
            ))}
          </div>
        )}
        {products.length > 0 && (
          <div className=" grid grid-cols-2 gap-x-6 gap-y-4   overflow-y-auto flex-1 mt-4">
            {products.map((e) => (
              <div
                onClick={async () => {
                  addItem(e);
                }}
                key={e.uuid}
                className="  rounded-lg transition-all hover:shadow-lg shadow-gray-200 bg-white h-[240px] border"
              >
                <div className="flex flex-col h-full cursor-pointer">
                  <div className=" h-3/4 relative">
                    {e.default_picture ? (
                      <img
                        src={e.default_picture}
                        alt=""
                        className="rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600"
                      />
                    ) : (
                      <img
                        src="/images/logo-niaga.png"
                        alt=""
                        className="rounded-tl-lg h-full w-full rounded-tr-lg object-cover bg-gray-600"
                      />
                    )}
                    <div className=" absolute top-2 right-2 py-1 px-2 rounded  bg-opacity-70 bg-pink-500 text-xs text-white">
                      {e.product_category}
                    </div>
                  </div>

                  <div className="h-1/4 px-3 py-2 flex flex-col">
                    <p className="text-xs font-semibold"> {e.name}</p>
                    <div className="flex text-xs font-semibold text-orange-600">
                      {money(e.prices[0].amount)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {products.length > 0 && (
          <div
            className="bg-gray-100 p-2 rounded-lg w-fit px-8 mt-4 "
            onClick={() => {
              setProducts([]);
              setSearch("");
            }}
            style={{ backgroundColor: merchant?.qr_menu_main_color ?? "purple", color: "white" }}
          >
            <h2 className="text-lg font-bold">Kembali</h2>
          </div>
        )}
      </div>
      {salesItems.length > 0 && (
        <div className="fixed bottom-0 right-0 m-4 ">
          <button
            type="button"
            className="flex flex-col p-2 border border-transparent shadow-sm text-sm font-medium rounded-full aspect-square justify-center items-center text-white"
            style={{
              backgroundColor: merchant?.qr_menu_secondary_color ?? "magenta",
            }}
            onClick={() => {
              setOpenCart(true);
            }}
          >
            <TbShoppingCart className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      )}

      <Drawer size={"xs"} open={openCart} onClose={() => setOpenCart(false)}>
        <Drawer.Header>
          <Drawer.Title>Meja {table?.text ?? ""}</Drawer.Title>
          <Drawer.Actions>
            <div className="flex justify-end items-center h-full">
              <PiUserCircle
                size={24}
                className="cursor-pointer"
                onClick={() => {
                  editCustomer();
                }}
              />
            </div>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 20, maxHeight: "initial" }}>
          <div className="flex flex-col h-full">
            <div className="flex-1">
              <OrderItems
                merchant={merchant}
                salesItems={salesItems}
                editItem={editItem}
                delItem={delItem}
                checkDiscounts={checkDiscounts}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  w-full"
                style={{ backgroundColor: merchant?.qr_menu_main_color?? "purple" }}
                onClick={async () => {
                  setOpenCart(false);
                  await editCustomer();
                  let result = await Swal.fire({
                    title: "Perhatian",
                    text: "Apakah Anda yakin ingin mengirim pesanan ini? Pastikan semua item sudah sesuai dengan keinginan Anda!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Kirim!",
                    cancelButtonText: "Batal",
                  });
                  if (result.isConfirmed) {
                    setIsLoading(true)
                    addPosQROrder({
                      merchant_table_occupancy_id: setNullString(""),
                      customer_id: setNullString(customer?.uuid),
                      customer_name: customerName,
                      customer_phone: customerPhone,
                      table_data: JSON.stringify(table),
                      room_id: roomId!,
                      order_type: "SELF_ORDER",
                      data: salesItems,
                    }, merchant!.uuid).then(v => v.json())
                    .then(v => {
                      Swal.fire({
                        title: "Perhatian",
                        text: "Order telah dikirim, Silakan Simpan Kode ini : " + v.data.code,
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "OK!",
                      });
                    })
                    .finally(() => setIsLoading(false))
                  }
                }}
              >
                Simpan
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
};
export default QrMenuPage;
