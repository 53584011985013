import { CompanySimple } from "../model/company";
import { Merchant } from "../model/merchant";
import { getMerchants } from "../repositories/merchant";
import {
  clearStorage,
  deleteCompanyStorage,
  getCompany,
  getMyCompanies,
  getPermissions,
  getProfileStorage,
  getSelectedMerchant,
  getSelectedMerchantID,
  requestPermission,
  setActiveMerchant,
  setCompanyStorage,
} from "../utils/helper";
import { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Avatar from "./avatar";
import MenuIcon from "./menu";
import { Profile } from "../model/profile";
import Loading from "./loading";

import {
  ArrowLeftIcon,
  ArrowLeftStartOnRectangleIcon,
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { IntlProvider } from "react-intl";
import { LoadingContext } from "../objects/loading_context";
import NoAccess from "./no_access";
import ModalSearch from "./modals/modal_search";
import { GLOBAL_SEARCH_LIST } from "../objects/constants";
import Back from "./back";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../utils/helper-ui";

export default function DashboardLayout({
  children,
  title,
  subtitle,
  permission,
}: {
  children: React.ReactNode;
  title?: String;
  subtitle?: String;
  permission?: string;
}) {
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const [company, setCompany] = useState<CompanySimple | null>(null);
  const [companies, setCompanies] = useState<CompanySimple[]>([]);
  const [fetchMerchants, setFetchMerchants] = useState(false);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [merchantID, setMerchantID] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant | null>(
    null
  );
  
  const nav = useNavigate();
  let { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalCompaniesOpen, setModalCompaniesOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [modalGlobalSearchOpen, setModalGlobalSearchOpen] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const logout = async () => {
    await clearStorage();
    window.location.href = "/login";
  };
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return
    requestPermission();
    getData();
  }, [mounted]);

  const getData = async () => {
    setCompany(await getCompany());
    let selectedMerchantID = await getSelectedMerchantID();

    if (selectedMerchantID) {
      setSelectedMerchant(await getSelectedMerchant());
      setMerchantID(selectedMerchantID);
    }

    setPermissions(await getPermissions());
    setProfile(await getProfileStorage());
    setCompanies(await getMyCompanies());
  };

  useEffect(() => {
    if (!merchantID) return;
    
  }, [merchantID]);

  useEffect(() => {
    getSelectedMerchantID().then((selectedMerchantID) => {
      if (!selectedMerchantID && !fetchMerchants && mounted) {
        getMerchantList();
      }
    });
  }, [company]);

  let renderMerchants = () => {
    return (
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
          {!company ? (
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Pilih Perusahaan
              </h1>
              <ul className=" max-h-80 overflow-y-auto">
                {companies.map((e) => (
                  <li
                    key={e.uuid}
                    className="flex  items-center  my-4 cursor-pointer"
                    onClick={async () => {
                      setIsLoading(true);
                      await setCompanyStorage(e);
                      setCompany(e);
                      getMerchants()
                        .then((v) => v.json())
                        .then((v) => setMerchants(v.data))
                        .catch((err) => errorToast(`${err}`))
                        .finally(() => {
                          setIsLoading(false);
                        });
                    }}
                  >
                    <Avatar
                      size={40}
                      marginRight={10}
                      name={e.name}
                      url={e.logo}
                    />
                    <span className=" text-lg font-normal hover:font-medium">
                      {e.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Pilih Merchant
              </h1>
              <ul className=" max-h-80 overflow-y-auto">
                {(merchants ?? []).map((e) => (
                  <li
                    key={e.uuid}
                    className="flex  items-center  my-4 cursor-pointer"
                    onClick={() => {
                      setIsLoading(true);
                      setActiveMerchant(e);
                      window.location.reload();
                    }}
                  >
                    <Avatar
                      size={40}
                      marginRight={10}
                      name={e.name}
                      url={e.logo}
                    />
                    <span className=" text-lg font-normal hover:font-medium">
                      {e.name}
                    </span>
                  </li>
                ))}
                <li
                  className="flex  items-center  my-4 cursor-pointer"
                  onClick={async () => {
                    setCompany(null);
                    await deleteCompanyStorage();
                  }}
                >
                  <Back
                    className="mr-2"
                    onClick={async () => {
                      setCompany(null);
                      await deleteCompanyStorage();
                    }}
                  />
                  <span className=" text-lg font-normal hover:font-medium">
                    Kembali
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getMerchantList = async () => {
    try {
      var resp = await getMerchants();
      var respJson = await resp.json();
      setFetchMerchants(true);
      setMerchants(respJson.data);
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    }
  };

  const sidebar = () => (
    <div className="w-[100px] bg-pink-900 text-gray-100 flex flex-col sidebar-bg">
      {/* Sidebar Header */}
      <div className="p-4 flex items-center justify-center">
        <img
          className=" w-12 h-12"
          src="/images/logo-jaraya-white.png"
          alt="logo"
        />
      </div>
      {/* Sidebar Menu */}
      {merchantID ? (
        <ul className="flex-1 overflow-y-auto">
          <li className="px-4 py-3 hover:bg-pink-700 flex justify-center cursor-pointer">
            <MenuIcon
              active="/images/home.png"
              inactive="/images/home-disabled.png"
              isActive={location.pathname == "/"}
              name="Beranda"
              link="/"
            />
          </li>

          <li className="px-4 py-3 hover:bg-pink-700 flex justify-center cursor-pointer">
            <MenuIcon
              active="/images/store.png"
              inactive="/images/store-disabled.png"
              isActive={location.pathname == "/cashier"}
              name="POS"
              link="/cashier"
            />
          </li>

          <li className="px-4 py-3 hover:bg-pink-700 flex justify-center cursor-pointer">
            <MenuIcon
              active="/images/report.png"
              inactive="/images/report-disabled.png"
              isActive={location.pathname == "/cashier/report"}
              name={"Laporan"}
              link="/cashier/report"
            />
          </li>
          <li className="px-4 py-3 hover:bg-pink-700 flex justify-center cursor-pointer">
            <MenuIcon
              active="/images/setting.png"
              inactive="/images/setting-disabled.png"
              isActive={location.pathname == "/setting"}
              name={"Pengaturan"}
              link="/setting"
            />
          </li>
        </ul>
      ) : (
        <div className="flex-1  "></div>
      )}

      {/* Sidebar Footer */}
      <div className="p-4">
        <button
          className="bg-pink-600 text-white px-4 py-2 rounded-full hover:bg-pink-700 flex items-center text-sm"
          onClick={logout}
        >
          <ArrowLeftStartOnRectangleIcon className="mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" />{" "}
        </button>
      </div>
    </div>
  );

  const topBar = () => (
    <div className="w-full bg-white border-b border-b-gray-200 p-2 flex items-center justify-between">
      <div className="flex items-center">
        <Avatar
          size={24}
          marginRight={10}
          name={profile?.full_name ?? ""}
          url={profile?.picture ?? ""}
        />
        <div className="flex flex-col">
          <span className="text-lg -mb-1 font-medium">
            {profile?.full_name}
          </span>
          <small className=" text-gray-400 text-xs">{profile?.email}</small>
        </div>
      </div>
      <div className="flex">
        {merchantID ? (
          <div
            className="flex items-center rounded-full border px-4 py-1 justify-between cursor-pointer"
            onClick={() => {
              // setSelectedMerchant(null)
              // setMerchantID(null)
              // getMerchantList()s
              setCompany(null);
              setModalCompaniesOpen(true);
            }}
          >
            <span className="mr-2 text-sm flex flex-col">
              <span>{selectedMerchant?.name}</span>
              <small className="-mt-1 text-gray-400 text-xs">
                {company?.name}
              </small>
            </span>
            <Avatar
              size={28}
              marginRight={0}
              name={selectedMerchant?.name ?? ""}
              url={selectedMerchant?.logo ?? ""}
            />
          </div>
        ) : null}
        <MagnifyingGlassIcon
          className="w-6 text-gray-400 ml-2 cursor-pointer"
          onClick={() => setModalGlobalSearchOpen(true)}
        />
      </div>
    </div>
  );
  const footer = () => (
    <div className="w-full border-t  border-t-gray-200 py-1 px-4 flex items-center justify-end">
      <small>&copy; 2024 Jaraya</small>
    </div>
  );

  return (
    <IntlProvider locale="id">
      <section className="content-wrapper flex h-screen w-full">
        {/* Sidebar */}
        {sidebar()}

        {/* Content Area */}
        <div className="flex-1 flex flex-col">
          {topBar()}
          <div
            ref={scrollRef}
            id="dashboard-content-wrapper"
            className="flex-1 overflow-y-auto"
          >
            {merchantID != null ? (
              permission != null && !permissions.includes(permission!) ? (
                <NoAccess />
              ) : (
                children
              )
            ) : (
              renderMerchants()
            )}
          </div>
          {footer()}
        </div>
        {isLoading && <Loading />}

        <ModalSearch
          footer={
            <div className="p-4">
              {company != null && (
                <button
                  type="button"
                  className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() => {
                    setCompany(null);
                  }}
                >
                  {" "}
                  <ChevronLeftIcon
                    className=" h-3 w-3 mr-1"
                    aria-hidden="true"
                  />{" "}
                  Kembali{" "}
                </button>
              )}
            </div>
          }
          isOpen={modalCompaniesOpen}
          setIsOpen={setModalCompaniesOpen}
          onChange={(val) => setSearch(val)}
        >
          {company == null ? (
            <ul role="list" className="divide-y divide-gray-100">
              {companies
                .filter((e) => e.name.toLowerCase().includes(search))
                .map((e) => (
                  <li
                    onClick={() => {
                      setIsLoading(true);
                      setCompany(e);
                      setSearch("");
                      setCompanyStorage(e).then(() => {
                        getMerchants()
                          .then((e) => e.json())
                          .then((e) => setMerchants(e.data ?? []))
                          .catch((err) => errorToast(`${err}`))
                          .finally(() => {
                            setIsLoading(false);
                          });
                      });
                    }}
                    key={e.uuid}
                    className="flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                  >
                    <div className="flex items-center mr-2">
                      <Avatar marginRight={20} name={e.name} url={e.logo} />
                      <div className="min-w-0">
                        <p className="text-sm font-semibold leading-6 ">
                          {e.name}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            <ul role="list" className="divide-y divide-gray-100">
              {(merchants ?? [])
                .filter((e) => e.name.toLowerCase().includes(search))
                .map((e) => (
                  <li
                    onClick={() => {
                      setSearch("");
                      setIsLoading(true);
                      setActiveMerchant(e);
                      window.location.reload();
                    }}
                    key={e.uuid}
                    className="flex items-center justify-between gap-x-4 px-3 py-5 hover:bg-gray-600 outline-none cursor-pointer text-gray-900 hover:text-white"
                  >
                    <div className="flex items-center mr-2">
                      <Avatar marginRight={20} name={e.name} url={e.logo} />
                      <div className="min-w-0">
                        <p className="text-sm font-semibold leading-6 ">
                          {e.name}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </ModalSearch>
        <ModalSearch
          isOpen={modalGlobalSearchOpen}
          setIsOpen={setModalGlobalSearchOpen}
          onClose={() => {
            setGlobalSearch("");
            setModalGlobalSearchOpen(false);
          }}
          onChange={(val) => setGlobalSearch(val)}
        >
          <ul role="list" className="divide-y divide-gray-100">
            {GLOBAL_SEARCH_LIST.filter((e) => {
              if (globalSearch.length == 0) {
                return false;
              }
              return (
                e.description
                  .toLowerCase()
                  .includes(globalSearch.toLowerCase()) ||
                e.name.toLowerCase().includes(globalSearch.toLowerCase())
              );
            }).map((e) => (
              <li
                key={e.key}
                className="flex flex-col py-4 px-8 hover:bg-slate-300 cursor-pointer"
                onClick={() => {
                  if (e.url) nav(e.url);
                }}
              >
                <h3 className=" text-xl font-semibold">{e.name}</h3>
                <small>{e.description}</small>
              </li>
            ))}
          </ul>
        </ModalSearch>
      </section>
    </IntlProvider>
  );
}
