import { customFetch } from "../utils/helper"

export let getProfile = async () => {
    return await customFetch("My/Profile")
}
export let getPermissions = async () => {
    return await customFetch("My/Permissions")
}

export let updateWebPushToken = async ( token: string, device_id: string) => {
    return await customFetch(
      `My/UpdateWebPushToken`,
      {
        method: "POST",
        body: JSON.stringify({ token, device_id, device_type: "web" }),
      }
    );
  };
  